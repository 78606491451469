import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";
import PharmacySelector from "@main/pharmacies/components/PharmacySelector";
import SaleSelector from "@main/sales/components/SaleSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}
interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function BalanceMovementDetail(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    abs_amount: t("amount"),
    type: t('type'),
    structure_type: t("structure_type"),    
    date: t("date"),
    concept: t('concept'),
    pharmacy_id: t('pharmacy_id'),
    sale_id: t('sale_id'),
  };

  let schema = {
    type: "object",
    properties: {
      abs_amount: {
        type: "number",
        title: translations.abs_amount,
        default: 0,
        readOnly: true,
      },
      type: {
        type: "number",
        default: 0, // BalanceMovement
        readOnly: true,
        oneOf: [
          {
            title: "Negativo",
            const: 0,
          },
          {
            title: "Positivo",
            const: 1,
          }
        ],        
      },
      date: {
        type: "string",
        title: translations.date,
        format: "date",
        readOnly: true,
      },
      concept: {
        type: "string",
        title: translations.concept,
        readOnly: true,
      },      
      sale_id: {
        type: ["number", "null"],
        title: translations.sale_id,
        default: null,
        readOnly: true,
      },
    },
    dependencies: {
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    pharmacy_id: {
      "ui:widget": "pharmacy-selector",
    },
    sale_id: {
      "ui:widget": "sale-selector",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{"sale-selector": SaleSelector},
    ...{"pharmacy-selector": PharmacySelector}
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default BalanceMovementDetail;
