import React, { createContext, useState } from "react";

interface CreateActionContextType {  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;  
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const CreateActionContext = createContext<CreateActionContextType>({  
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},
  loading: false,
  setLoading: () => {},
});

interface CreateActionProviderProps {
  children: React.ReactNode;
}

export const CreateActionProvider: React.FC<CreateActionProviderProps> = ({
  children,
}) => {  
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);
  const [loading, setLoading] = useState(false); 

  const value = {    
    backendFormErrors,
    setBackendFormErrors,
    showModal,
    setShowModal,
    element,
    setElement,
    loading,
    setLoading  
  };
  return (
    <CreateActionContext.Provider value={value}>
      {children}
    </CreateActionContext.Provider>
  );
};
