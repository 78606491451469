import React, { useContext, useEffect } from "react";
import getConfig from "../configs/commercialResourceTableConfig";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useGridListAction from "../actions/grid-list-commercial-resource/useGridListAction";
import { Card } from "react-bootstrap";
import useDownloadAction from "../actions/download/useDownloadAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
/**<newActionsImport>**/

function CommercialResourcesGridPage() {
  const { user } = useContext(AuthContext);
  
  const { downloadFunction } = useDownloadAction();
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;

  const { indexFunction, actionTemplate: listGridActionTemplate, setTableConfig } = 
    useGridListAction(user, {type: '2'}, {downloadFunction}); // CommercialResource  

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let CommercialResourceTableConfig = getConfig({    
    indexFunction, // We pass the function to index the table    
    /**newActionsTableConfig**/
  });
  useEffect(() => {
    setTableConfig(CommercialResourceTableConfig);
  }, [CommercialResourceTableConfig])

  return (
      <>
        <section className="hero not-full" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>
          <div className="container">
            <div className="hero-content">
              <h1 className="hero-title xl">Recursos comerciales</h1>
              <p className="hero-description">Accede a materiales comerciales y de comunicación: imágenes y creatividades para tu web y social media.</p>
            </div>
            <div className="actions">
            <a href="/pharmacy/panel/communication-resources" className="btn">Recursos de comunicación <FontAwesomeIcon icon={faArrowRight} /></a>
            </div>
          </div>
        </section>
        <div className="container mt-5 mb-5">
          {listGridActionTemplate}
        </div>
      </>

  );
}

export default CommercialResourcesGridPage;
