import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import React, { useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import PharmacyAppNavbar from "./PharmacyAppNavbar";
import '../css/pharmacyCustom.css';

import { t } from "i18next";
import BeelditScrollToTop from "@beeldit/core/components/BeelditScrollTop";

function PharmacyPanel() {
  const { tokenLoaded, user } = useContext(AuthContext);
  const navigate = useNavigate();
  const logoName = "/"+process.env.REACT_APP_LOGO_NAME;
  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  // Si el usuario existe tenemos que comprobar si todos sus campos están rellenos. sino lo redirigimos a la página de my_account
  const pharmacy = user.extra_data.pharmacy;
  const path = window.location.pathname;
  if ((
    !pharmacy.address ||
    !pharmacy.cif ||
    !pharmacy.city ||
    !pharmacy.contact_email ||
    !pharmacy.contact_phone ||
    !pharmacy.fiscal_name ||
    !pharmacy.name ||
    !pharmacy.postal_code) && path !== '/pharmacy/panel/my_account'
  ) {
    navigate('/pharmacy/panel/my_account');
  }

  const items: any[] = [
    {
      label: t("home"),
      to: `/pharmacy/panel/home`,
    },
    {
      label: t("my_account"),
      children: [
        {
          label: t("profile"),
          to: `/pharmacy/panel/my_account`,
        },
        {
          label: t("balance_movements"),
          to: `/pharmacy/panel/balance_movements`,
        },
      ]      
    },
    {
      label: t("offers"),
      to: `/pharmacy/panel/offers`,
    },
    {
      label: t("resources"),
      children: [
        {
          label: t("commercial_resources"),
          to: `/pharmacy/panel/commercial-resources`,
        },
        {
          label: t("communication_resources"),
          to: `/pharmacy/panel/communication-resources`,
        },
      ]
    },    
    {
      label: t("trainings"),
      to: `/pharmacy/panel/trainings`,
    },
    {
      label: t("contact"),
      to: `/pharmacy/panel/contact`,
      icon: icon({ name: "envelope", style: "solid" }),
    },
    {
      label: t("exit"),
      to: `/login`,
    }
  ];

  return (
      <div id="pharmacy-panel">
        <BeelditScrollToTop></BeelditScrollToTop>
        <PharmacyAppNavbar items={items} />
        <div className="pharmacy">
          <div className="page-content">
            <Outlet />
          </div>
          <footer>
            <div className="footer-content">
             <div className="footer-left">
               <img
                   src={logoName}
                   className="img img-fluid"
                   alt="App Icon"
               />

             </div>
             <div className="footer-center">
              <a href="">¿QUE ES THE GOOD SOCIETY?</a>
              <a href="">TÉRMINOS Y CONDICIONES</a>
              <a href="/pharmacy/panel/contact/1#contact-form">SOLICITA UNA FORMACIÓN</a>
              <a href="/pharmacy/panel/contact/2#contact-form">SOLICITA UNA VISITA COMERCIAL</a>
             </div>
              <div className="footer-right">
                <a href="/pharmacy/panel/contact/3#contact-form">
                  <div>
                    <img src="/chat-bubble.png" alt="chat contact" />
                  </div>
                  
                  <div className="texts">
                    <div>¿Tienes dudas?</div>
                    <div>¡CONTÁCTANOS!</div>
                  </div>
                </a>
              </div>
            </div>
            <hr/>
            <div className="footer-copy">
              <p>2022 © Botanicapharma | Todos los derechos reservados</p>
            </div>
          </footer>
        </div>
      </div>
  );
}

export default PharmacyPanel;
