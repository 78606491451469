import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';
import React, { useContext, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';


interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
}

function AppNavbar(props: any) {
  const { user } = useContext(AuthContext);
  const [expanded, setExpanded] = useState(false);

  const {items} = props

  const navigate = useNavigate();
  const handleLogout = () => {
    navigate('/login');
  };
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" expanded={expanded}>
        <Navbar.Brand href="https://botanicapharma.com/">
            <img src="/logo.png" alt="Botanicapharma" width="80"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(expanded ? false : true)} />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-0 ms-auto">
            {items.map((item: MenuItem) => (
              item.children ?
                <NavDropdown title={item.label} id="collasible-nav-dropdown" >
                  {item.children.map((child: MenuItem) => (
                    <NavDropdown.Item as={NavLink} to={child.to} onClick={() => setExpanded(false)}>{child.label}</NavDropdown.Item>
                  ))}
                </NavDropdown>
              :
              <Nav.Link as={NavLink} to={item.to} onClick={() => setExpanded(false)}>{item.label}</Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
    </Navbar>
  );
}

export default AppNavbar;

