import { AuthContext } from '@beeldit/user-and-access/auth/AuthContext';
import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink, useNavigate } from 'react-router-dom';


interface MenuItem {
  label: string;
  to: string;
  children?: MenuItem[];
}

function AdminAppNavbar(props: any) {
  const { user } = useContext(AuthContext);

  const {items} = props

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top">
        <Navbar.Brand href="#home">
            <img src="/logo.png" alt="BotanicaNutrients" width="120"/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {items.map((item: MenuItem) => (
              <Nav.Link className="d-block d-md-none"  as={NavLink} to={item.to}>{item.label}</Nav.Link>
            ))}
          </Nav>
          
        </Navbar.Collapse>
    </Navbar>
  );
}

export default AdminAppNavbar;

