import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import Login from "@beeldit/user-and-access/auth/pages/Login";
import ContactForm from "@main/contact/pages/ContactForm";
import { AuthProvider } from "@beeldit/user-and-access/auth/AuthContext";
import AdminPanel from "./panel-components/AdminPanel";
import PharmacyPanel from "./panel-components/PharmacyPanel";

import { GridListActionProvider as GridCommunicationResourceListActionProvider } from "./resources/actions/grid-list-communication-resource/GridListActionContext";
import { GridListActionProvider as GridCommunicationResourcePetListActionProvider } from "./resources/actions/grid-list-communication-pet-resource/GridListActionContext";
import { GridListActionProvider as GridCommunicationResourceMainListActionProvider } from "./resources/actions/grid-list-main-communication-resource/GridListActionContext";
import { GridListActionProvider as GridCommercialResourceListActionProvider } from "./resources/actions/grid-list-commercial-resource/GridListActionContext";
import { GridListActionProvider as GridOfferListActionProvider } from "./offers/actions/grid-list/GridListActionContext";
import { GridListActionProvider as GridTrainingListActionProvider } from "./trainings/actions/grid-list/GridListActionContext";

import { ListActionProvider as SaleListActionProvider } from "./sales/actions/list/ListActionContext";
import { CreateActionProvider as SaleCreateActionProvider } from "./sales/actions/create/CreateActionContext";
import { RemoveActionProvider as SaleRemoveActionProvider } from "./sales/actions/remove/RemoveActionContext";
import { EditActionProvider as SaleEditActionProvider } from "./sales/actions/edit/EditActionContext";

import { ListActionProvider as PharmacyListActionProvider } from "./pharmacies/actions/list/ListActionContext";
import { CreateActionProvider as PharmacyCreateActionProvider } from "./pharmacies/actions/create/CreateActionContext";
import { ImportActionProvider as PharmacyImportActionProvider } from "./pharmacies/actions/import/ImportActionContext";
import { RemoveActionProvider as PharmacyRemoveActionProvider } from "./pharmacies/actions/remove/RemoveActionContext";
import { EditActionProvider as PharmacyEditActionProvider } from "./pharmacies/actions/edit/EditActionContext";
import { DetailsActionProvider as PharmacyDetailsActionProvider } from "./pharmacies/actions/details/DetailsActionContext";
import { ProfileActionProvider as PharmacyProfileActionProvider } from "./pharmacies/actions/profile/ProfileActionContext";

import { ListActionProvider as LevelListActionProvider } from "./levels/actions/list/ListActionContext";
import { CreateActionProvider as LevelCreateActionProvider } from "./levels/actions/create/CreateActionContext";
import { RemoveActionProvider as LevelRemoveActionProvider } from "./levels/actions/remove/RemoveActionContext";
import { EditActionProvider as LevelEditActionProvider } from "./levels/actions/edit/EditActionContext";
import { DetailsActionProvider as LevelDetailsActionProvider } from "./levels/actions/details/DetailsActionContext";

import { ListActionProvider as BalanceMovementListActionProvider } from "./balance_movements/actions/list/ListActionContext";
import { CreateActionProvider as BalanceMovementCreateActionProvider } from "./balance_movements/actions/create/CreateActionContext";
import { RemoveActionProvider as BalanceMovementRemoveActionProvider } from "./balance_movements/actions/remove/RemoveActionContext";
import { EditActionProvider as BalanceMovementEditActionProvider } from "./balance_movements/actions/edit/EditActionContext";
import { DetailsActionProvider as BalanceMovementDetailActionProvider } from "./balance_movements/actions/details/DetailsActionContext";

import { ListActionProvider as OfferListActionProvider } from "./offers/actions/list/ListActionContext";
import { CreateActionProvider as OfferCreateActionProvider } from "./offers/actions/create/CreateActionContext";
import { RemoveActionProvider as OfferRemoveActionProvider } from "./offers/actions/remove/RemoveActionContext";
import { EditActionProvider as OfferEditActionProvider } from "./offers/actions/edit/EditActionContext";
import { DownloadPDFProvider as OfferDownloadPDFActionProvider } from "./offers/actions/download-pdf/DownloadPDFActionContext";

import { ListActionProvider as TrainingListActionProvider } from "./trainings/actions/list/ListActionContext";
import { CreateActionProvider as TrainingCreateActionProvider } from "./trainings/actions/create/CreateActionContext";
import { RemoveActionProvider as TrainingRemoveActionProvider } from "./trainings/actions/remove/RemoveActionContext";
import { EditActionProvider as TrainingEditActionProvider } from "./trainings/actions/edit/EditActionContext";
import { DisplayVideoActionProvider as TrainingDisplayVideoActionProvider } from "./trainings/actions/display-video/DisplayVideoActionContext";
import { DownloadPDFProvider as TrainingDownloadPDFActionProvider } from "./trainings/actions/download-pdf/DownloadPDFActionContext";

import { ListActionProvider as CommercialListActionProvider } from "./commercials/actions/list/ListActionContext";
import { CreateActionProvider as CommercialCreateActionProvider } from "./commercials/actions/create/CreateActionContext";
import { RemoveActionProvider as CommercialRemoveActionProvider } from "./commercials/actions/remove/RemoveActionContext";
import { EditActionProvider as CommercialEditActionProvider } from "./commercials/actions/edit/EditActionContext";

import SalesPage from "@main/sales/pages/SalesPage";
import PharmaciesPage from "./pharmacies/pages/PharmaciesPage";
import LevelsPage from "./levels/pages/LevelsPage";
import BalanceMovementsPage from "@main/balance_movements/pages/BalanceMovementsPage";
import OffersPage from "@main/offers/pages/OffersPage";
import TrainingsPage from "./trainings/pages/TrainingsPage";
import ResourcesPage from "./resources/pages/ResourcesPage";
import OffersGridPage from "./offers/pages/OffersGridPage";
import CommercialResourcesGridPage from "./resources/pages/CommercialResourcesGridPage";
import CommunicationResourcesGridPage from "./resources/pages/CommunicationResourcesGridPage";
import TrainingsGridPage from "./trainings/pages/TrainingsGridPage";
import PharmacyTabsPage from "./pharmacies/pages/PharmacyTabsPage";
import ResetPassword from "@beeldit/user-and-access/auth/pages/ResetPassword";
import RequestResetPassword from "@beeldit/user-and-access/auth/pages/RequestResetPassword";
import PharmacyProfilePage from "./pharmacies/pages/PharmacyProfilePage";
import HomePage from "./pages/HomePage";
import CommercialsPage from "@main/commercials/pages/CommercialsPage";
import RouterRoleGuard from "@beeldit/user-and-access/auth/guards/RouterRoleGuard";
import homeRedirect from "@beeldit/user-and-access/auth/services/homeRedirect";
import BalanceMovementsPharmacyPage from "@main/balance_movements/pages/BalanceMovementsPharmacyPage";

const redirectUrls = {
  admin: "/admin/panel/pharmacies",
  pharmacy: "/pharmacy/panel/home",
};
const user = JSON.parse(localStorage.getItem("user") || "{}");

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <div className="App">
          <Outlet />
        </div>
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        index: true,
        element: <Navigate to={homeRedirect(redirectUrls, user)} />,
      },
      {
        path: "/login",
        element: <Login redirectUrls={redirectUrls} />,
      },
      {
        path: "/resetPassword",
        element: <ResetPassword />,
      },
      {
        path: "/request-reset-password",
        element: <RequestResetPassword />,
      },
      {
        path: "/admin/panel",
        element: (
          <RouterRoleGuard allowedRoles={["admin"]}>
            <AdminPanel />
          </RouterRoleGuard>
        ),
        children: [
          {
            path: "sales",
            element: (
              <SaleListActionProvider>
                <SaleRemoveActionProvider>
                  <SaleCreateActionProvider>
                    <SaleEditActionProvider>
                      <SalesPage />
                    </SaleEditActionProvider>
                  </SaleCreateActionProvider>
                </SaleRemoveActionProvider>
              </SaleListActionProvider>
            ),
          },
          {
            path: "pharmacies",
            element: (
              <PharmacyListActionProvider>
                <PharmacyRemoveActionProvider>
                  <PharmacyCreateActionProvider>
                    <PharmacyImportActionProvider>
                      <PharmacyEditActionProvider>
                        <PharmaciesPage />
                      </PharmacyEditActionProvider>
                    </PharmacyImportActionProvider>
                  </PharmacyCreateActionProvider>
                </PharmacyRemoveActionProvider>
              </PharmacyListActionProvider>
            ),
          },
          {
            path: "levels",
            element: (
              <LevelListActionProvider>
                <LevelRemoveActionProvider>
                  <LevelCreateActionProvider>
                    <LevelEditActionProvider>
                      <LevelsPage />
                    </LevelEditActionProvider>
                  </LevelCreateActionProvider>
                </LevelRemoveActionProvider>
              </LevelListActionProvider>
            ),
          },
          {
            path: "pharmacy/:pharmacyId",
            element: (
              <PharmacyDetailsActionProvider>
                <PharmacyTabsPage />
              </PharmacyDetailsActionProvider>
            ),
          },
          {
            path: "balance_movements",
            element: (
              <BalanceMovementListActionProvider>
                <BalanceMovementRemoveActionProvider>
                  <BalanceMovementCreateActionProvider>
                    <BalanceMovementEditActionProvider>
                      <BalanceMovementsPage />
                    </BalanceMovementEditActionProvider>
                  </BalanceMovementCreateActionProvider>
                </BalanceMovementRemoveActionProvider>
              </BalanceMovementListActionProvider>
            ),
          },
          {
            path: "offers",
            element: (
              <OfferDownloadPDFActionProvider>
                <OfferListActionProvider>
                  <OfferRemoveActionProvider>
                    <OfferCreateActionProvider>
                      <OfferEditActionProvider>
                        <OffersPage />
                      </OfferEditActionProvider>
                    </OfferCreateActionProvider>
                  </OfferRemoveActionProvider>
                </OfferListActionProvider>
              </OfferDownloadPDFActionProvider>
            ),
          },
          {
            path: "trainings",
            element: (
              <TrainingDownloadPDFActionProvider>
                <TrainingListActionProvider>
                  <TrainingRemoveActionProvider>
                    <TrainingCreateActionProvider>
                      <TrainingEditActionProvider>
                        <TrainingsPage />
                      </TrainingEditActionProvider>
                    </TrainingCreateActionProvider>
                  </TrainingRemoveActionProvider>
                </TrainingListActionProvider>
              </TrainingDownloadPDFActionProvider>
            ),
          },
          {
            path: "resources",
            element: <ResourcesPage />,
          },
          {
            path: "commercials",
            element: (
              <CommercialListActionProvider>
                  <CommercialRemoveActionProvider>
                      <CommercialCreateActionProvider>
                          <CommercialEditActionProvider>
                              <CommercialsPage />
                          </CommercialEditActionProvider>
                      </CommercialCreateActionProvider>
                  </CommercialRemoveActionProvider>
              </CommercialListActionProvider>
            ),
          },
        ],
      },
      {
        path: "/pharmacy/panel/",
        element: (
          <RouterRoleGuard allowedRoles={["pharmacy"]}>
            <PharmacyPanel />
          </RouterRoleGuard>
        ),
        children: [
          {
            path: "home",
            element: <HomePage />,
          },
          {
            path: "my_account",
            element: (
              <PharmacyProfileActionProvider>
                <PharmacyProfilePage />
              </PharmacyProfileActionProvider>
            ),
          },
          {
            path: "offers",
            element: (
              <GridOfferListActionProvider>
                <OffersGridPage />
              </GridOfferListActionProvider>
            ),
          },
          {
            path: "commercial-resources",
            element: (
              <GridCommercialResourceListActionProvider>
                <CommercialResourcesGridPage />
              </GridCommercialResourceListActionProvider>
            ),
          },
          {
            path: "communication-resources",
            element: (
              <GridCommunicationResourceListActionProvider>
              <GridCommunicationResourcePetListActionProvider>
              <GridCommunicationResourceMainListActionProvider>
                <CommunicationResourcesGridPage />

              </GridCommunicationResourceMainListActionProvider>

              </GridCommunicationResourcePetListActionProvider>
              </GridCommunicationResourceListActionProvider>
            ),
          },
          {
            path: "trainings",
            element: (
              <TrainingDisplayVideoActionProvider>
                <GridTrainingListActionProvider>
                  <TrainingsGridPage />
                </GridTrainingListActionProvider>
              </TrainingDisplayVideoActionProvider>
            ),
          },
          {
            path: "balance_movements",
            element: (
              <BalanceMovementListActionProvider>
                <BalanceMovementDetailActionProvider>
                  <BalanceMovementsPharmacyPage />
                </BalanceMovementDetailActionProvider>
              </BalanceMovementListActionProvider>
            ),
          },
          {
            path: "contact/:typeId?",
            element: <ContactForm />,
          },
        ],
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
