import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";
import { EditActionContext } from "./EditActionContext";

function useGet() {
  const { setElement, setShowModal, setBackendFormErrors } =
    useContext(EditActionContext);
  const errorHandler = useErrorHandler();

  return (
    id: any,
    onSuccess: any = (data: any) => {
      return data;
    }
  ) => {
    setBackendFormErrors({});
    get("contents", id)
      .then((response: any) => {
        let data = response.data;
        data = onSuccess(response.data);
        setElement(data);
        setShowModal(true);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useGet;
