import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { PharmacyContext } from "../contexts/PharmacyContext";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import LevelSelector from "@main/levels/components/LevelSelector";
import CommercialSelector from "@main/commercials/components/CommercialSelector";
import StateSelector from "@main/states/components/StateSelector";
import { ProfileActionContext } from "../actions/profile/ProfileActionContext";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function PharmacyProfileForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);
  const { editionMode } = useContext(ProfileActionContext);

  const { t } = useTranslation();

  const translations = {
    name: t("pharmacy_name"),
    fiscal_name: t("fiscal_name"),
    cif: t("cif"),
    contact_email: t("contact_email"),
    contact_phone: t("contact_phone"),
    contact_mobile_phone: t("contact_mobile_phone"),
    rrss: t("rrss"),
    address: t("address"),
    postal_code: t("postal_code"),
    city: t("city"),
    state_id: t("state"),
    instagram: t("instagram"),
    web: t("web"),
    purchases_responsible_name: t("purchases_responsible_name"),
    account_details: t("account_details"),
  };

  let accountDetailsSchema: RJSFSchema = {
    type: "object",
    // title: translations.account_details,
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      name: {
        type: "string",
        title: translations.name,        
      },
      fiscal_name: {
        type: "string",
        title: translations.fiscal_name,
      },
      cif: {
        type: "string",
        title: translations.cif,
        readOnly: true,
      },
      contact_email: {
        type: "string",
        title: translations.contact_email,
        readOnly: true,
      },
      contact_phone: {
        type: "string",
        title: translations.contact_phone,
      },
      contact_mobile_phone: {
        type: "string",
        title: translations.contact_mobile_phone,
      },
      rrss: {
        type: ["object", "null"],
        title: "",
        properties: {
          web: {
            type: "string",
            title: translations.web,
          },
          ig: {
            type: "string",
            title: translations.instagram,
          },
        },
      },
      purchases_responsible_name: {
        type: "string",
        title: translations.purchases_responsible_name,
      },
    },
  };
  let addressDataSchema: RJSFSchema = {
    type: "object",
    // title: translations.address,
    // required: ['minutes', 'customer_id', 'task', 'date', 'report_id'],
    properties: {
      address: {
        type: "string",
        title: translations.address,
      },
      postal_code: {
        type: "string",
        title: translations.postal_code,
      },
      city: {
        type: "string",
        title: translations.city,
      },
      state_id: {
        type: ["integer", "null"],
        title: translations.state_id,
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    name: {
      "ui:disabled": editionMode ? false : true,
    },
    fiscal_name: {
      "ui:disabled": editionMode ? false : true,
    },
    cif: {
      "ui:disabled": true,
    },
    contact_email: {
      "ui:disabled": true,
    },
    contact_phone: {
      "ui:disabled": editionMode ? false : true,
    },
    contact_mobile_phone: {
      "ui:disabled": editionMode ? false : true,
    },
    rrss: {
      "ui:disabled": editionMode ? false : true,
    },
    purchases_responsible_name: {
      "ui:disabled": editionMode ? false : true,
    },
    address: {
      "ui:disabled": editionMode ? false : true,
    },
    postal_code: {
      "ui:disabled": editionMode ? false : true,
    },
    city: {
      "ui:disabled": editionMode ? false : true,
    },
    state_id: {
      "ui:widget": "state-selector",
      "ui:disabled": editionMode ? false : true,
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "state-selector": StateSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  // const processSchemaFunction = useProcessSchema();

  // useEffect(() => {
  //   setFinalSchema(processSchemaFunction(schema, element));
  // }, [element]);

  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <JSONSchemaForm
            noHtml5Validate
            showErrorList={false}
            ref={formRef}
            schema={accountDetailsSchema}
            uiSchema={uiSchema}
            widgets={customWidgets}
            formData={element}
            validator={validator}
            onChange={handleChange}
            onSubmit={onSubmit}
            onError={log("errors")}
            extraErrors={backendFormErrors}
          />
        </div>
        <div className="col-md-6">
          <JSONSchemaForm
            noHtml5Validate
            showErrorList={false}
            ref={formRef}
            schema={addressDataSchema}
            uiSchema={uiSchema}
            widgets={customWidgets}
            formData={element}
            validator={validator}
            onChange={handleChange}
            onSubmit={onSubmit}
            onError={log("errors")}
            extraErrors={backendFormErrors}
          />
        </div>
      </div>
    </>
  );
}

export default PharmacyProfileForm;
