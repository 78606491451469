import { useContext } from "react";
import { KPIsActionContext } from "./KPIsActionContext";
import useKPIs from "./useKPIs";
import { Card } from "react-bootstrap";

function useKPIsAction(): any {
  const kpis = useKPIs();
  const kpisFunction = (id: number) => kpis(id);

  const { element } = useContext(KPIsActionContext);

  const actionTemplate = (
    <div>
      {element ? (
        <>
          <Card>
            <Card.Body>
              <div className="kpy">
                <div className="kpy-item">
                  <div className="kpy-content">
                    <h1 className="kpy-title">{element.usersCount}</h1>
                    <span className="kpy-description">
                      Total de pacientes suscritos
                    </span>
                  </div>
                </div>
                <div className="kpy-item">
                  <div className="kpy-content">
                    <h1 className="kpy-title">{element.redeemedUsersCount}</h1>
                    <span className="kpy-description">
                      Total de pacientes canjeados
                    </span>
                  </div>
                </div>                
                <div className="kpy-item">
                  <div className="kpy-content">
                    <h1 className="kpy-title">{new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(element.salesCount)}</h1>
                    <span className="kpy-description">
                      Total de ventas de tus pacientes
                    </span>
                  </div>
                </div>
                <div className="kpy-item col-span-2">
                  <div className="kpy-content">
                    <h1 className="kpy-title">{new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(element.acumulatedBalance)}</h1>
                    <span className="kpy-description">
                      Tu saldo acumulado
                    </span>
                  </div>
                </div>
                <div className="kpy-item">
                  <div className="kpy-content">
                    <h1 className="kpy-title">{new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(element.currentBalance)}</h1>
                    <span className="kpy-description">
                      Tu saldo actual disponible
                    </span>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </>
      ) : (
        "loading"
      )}
    </div>
  );

  return {
    kpisFunction,
    actionTemplate,
  };
}

export default useKPIsAction;
