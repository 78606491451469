import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { PharmacyContext } from "../contexts/PharmacyContext";
import { useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import LevelSelector from "@main/levels/components/LevelSelector";
import CommercialSelector from "@main/commercials/components/CommercialSelector";
import StateSelector from "@main/states/components/StateSelector";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
    className?: string;
  };
}

interface Context {
  element: any;
  setElement: (element: any) => any;
  backendFormErrors: any;
}

function PharmacyForm(props: Prop) {
  const { formRef, onSubmit, context, className } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t('pharmacy_name'),
    fiscal_name: t('fiscal_name'),
    cif: t('cif'),
    contact_email: t('contact_email'),
    contact_phone: t('contact_phone'),
    contact_mobile_phone: t('contact_mobile_phone'),
    address: t('address'),
    postal_code: t('postal_code'),
    rrss: t('rrss'),
    city: t('city'),
    state_id: t('state'),
    level_id: t('level'),
    purchases_responsible_name: t('purchases_responsible_name'),
    latitude: t('latitude'),
    longitude: t('longitude'),
    last_incentive: t('last_incentive'),
    comercial_id: t('comercial'),
    web: t('web'),
    instagram: t('instagram'),
    logo: t('logo'),
  };

  let schema: RJSFSchema = {
    type: "object",
    properties: {
      name: {
        type: 'string',
        title: translations.name + '*',
      },
      fiscal_name: { 
        type: ['string', 'null'],
        title: translations.fiscal_name,
      },
      cif: {
        type: 'string',
        title: translations.cif + '*',
        readOnly: element?.id ? true : false,
      },
      contact_email: {
        type: 'string',
        title: translations.contact_email + '*',
        readOnly: element?.id ? true : false,
      },
      contact_phone: {
        type: ['string', 'null'],
        title: translations.contact_phone,
      },
      contact_mobile_phone: {
        type: ['string', 'null'],
        title: translations.contact_mobile_phone,
      },
      address: {
        type: ['string', 'null'],
        title: translations.address,
      },
      postal_code: {
        type: ['string', 'null'],
        title: translations.postal_code,
      },
      rrss: {
        type: ['object', 'null'],
        title: '',
        properties: {
          web: {
            type: 'string',
            title: translations.web + '*',
          },
          ig: {
            type: 'string',
            title: translations.instagram + '*',
          },
        },
      },
      city: {
        type: ['string', 'null'],
        title: translations.city,
      },
      state_id: {
        type: ['integer', 'null'],
        title: translations.state_id ,
      },
      level_id: {
        type: ['integer', 'null'],
        title: translations.level_id,
      },      
      last_incentive: {
        type: ['number', 'null'],
        title: translations.last_incentive,
        default: 0,
      },
      purchases_responsible_name: {
        type: ['string', 'null'],
        title: translations.purchases_responsible_name,
      },
      commercial_id: {
        type: ['integer', 'null'],
        title: translations.comercial_id ,
      },
      logo: {
        type: "array",
        title: translations.logo,
        description: "(Imagen del logotipo en formato png o jpg)",
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        }
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:classNames": className,
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    name: {
      "ui:placeholder": translations.name,
    },
    fiscal_name: {
      "ui:placeholder": translations.fiscal_name,
    },
    cif: {
      "ui:placeholder": translations.cif,
    },
    contact_email: {
      "ui:placeholder": translations.contact_email,
    },
    contact_phone: {
      "ui:placeholder": translations.contact_phone,
    },
    contact_mobile_phone: {
      "ui:placeholder": translations.contact_mobile_phone,
    },
    address: {
      "ui:placeholder": translations.address,
    },
    postal_code: {
      "ui:placeholder": translations.postal_code,
    },
    rrss: {
      "ui:placeholder": translations.rrss,
      ig: {
        "ui:placeholder": translations.instagram,
      }     
    },
    city: {
      "ui:placeholder": translations.city,
    },        
    level_id: {
      "ui:widget": "level-selector",
      "ui:placeholder": translations.level_id,
    },
    latitude: {
      "ui:placeholder": translations.latitude,
    },
    longitude: {
      "ui:placeholder": translations.longitude,
    },
    commercial_id: {
      "ui:widget": "commercial-selector",
      "ui:placeholder": translations.comercial_id,
    },
    state_id: {
      "ui:widget": "state-selector",
      "ui:placeholder": translations.state_id,
    },
    last_incentive: {
      "ui:placeholder": translations.last_incentive,
    },
    purchases_responsible_name: {
      "ui:placeholder": translations.purchases_responsible_name,
    },
    logo: {
      "ui:widget": "file-input",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{ "level-selector": LevelSelector },
    ...{ "commercial-selector": CommercialSelector },
    ...{ "state-selector": StateSelector },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={schema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default PharmacyForm;
