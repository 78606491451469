import { t } from "i18next";
import { Card, Tab, Tabs } from "react-bootstrap";

import { RemoveActionProvider as RemoveActionProvider } from "./../actions/remove/RemoveActionContext";

import { ListActionProvider as CommunicationResourceListActionProvider } from "./../actions/list-communication-resource/ListActionContext";
import { CreateActionProvider as CommunicationResourceCreateActionProvider } from "./../actions/create-communication-resource/CreateActionContext";
import { EditActionProvider as CommunicationResourceEditActionProvider } from "./../actions/edit-communication-resource/EditActionContext";
import CommunicationResourcePage from "./CommunicationResourcePage";

import { ListActionProvider as CommercialResourceListActionProvider } from "./../actions/list-commercial-resource/ListActionContext";
import { CreateActionProvider as CommercialResourceCreateActionProvider } from "./../actions/create-commercial-resource/CreateActionContext";
import { EditActionProvider as CommercialResourceEditActionProvider } from "./../actions/edit-commercial-resource/EditActionContext";
import { DownloadPDFProvider as CommercialDownloadPDFActionProvider } from "./../actions/download-pdf/DownloadPDFActionContext";
import CommercialResourcePage from "./CommercialResourcePage";

/**<newActionsImport>**/

function ResourcesPage() {
  return (
    <Tabs
      id="controlled-tab-example"
      defaultActiveKey="commercial"
      className=""
    >
      <Tab eventKey="commercial" title={t("commercial_resources")}>
        <CommercialDownloadPDFActionProvider>
          <CommercialResourceListActionProvider>
            <RemoveActionProvider>
              <CommercialResourceCreateActionProvider>
                <CommercialResourceEditActionProvider>
                  <CommercialResourcePage />
                </CommercialResourceEditActionProvider>
              </CommercialResourceCreateActionProvider>
            </RemoveActionProvider>
          </CommercialResourceListActionProvider>
        </CommercialDownloadPDFActionProvider>
      </Tab>
      <Tab eventKey="communication" title={t("communication_resources")}>
        <CommunicationResourceListActionProvider>
          <RemoveActionProvider>
            <CommunicationResourceCreateActionProvider>
              <CommunicationResourceEditActionProvider>
                <CommunicationResourcePage />
              </CommunicationResourceEditActionProvider>
            </CommunicationResourceCreateActionProvider>
          </RemoveActionProvider>
        </CommunicationResourceListActionProvider>
      </Tab>
    </Tabs>
  );
}

export default ResourcesPage;
