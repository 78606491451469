import { User } from "../../users/models/User";

function homeRedirect(redirectUrls: any, user: User): string {
  let url = "/login";
  let role = user.role;
  if(redirectUrls && redirectUrls[role]) {
    url = redirectUrls[role];
  }
  return url;
}
export default homeRedirect;