import { useContext, useEffect } from "react";
import useIndex from "./useIndex";
import { User } from "@beeldit/user-and-access/users/models/User";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import { GridListActionContext } from "./GridListActionContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import getFilterUIConfig from "@main/resources/configs/filterUIConfig";
import getFiltersConfig from "@main/resources/configs/filterConfig";
import { t } from "i18next";

function useGridListActionMain(user: any, filters: any = {}, functions: any = {}): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();

  /** Crud functions */
  const index = useIndex();
  const indexFunctionMain = () => index();

  /** Filter loading */
  const { filtersLoaded, setFiltersLoaded } = useContext(GridListActionContext);
  const { tableFilters, setTableFilters } = useContext(GridListActionContext);
  const { tableConfig, setTableConfigMain } = useContext(GridListActionContext);
  useFilters({ ...{}, ...filters }, GridListActionContext);

  /**Order by config */
  const { orderBy, setOrderBy } = useContext(GridListActionContext);

  const onOrderByChange = (orderBy: string) => {
    setOrderBy(orderBy);
  };

  /**
   * Pagination config
   */
  const { paginationConfig, setPaginationConfig } = useContext(
    GridListActionContext
  );

  const onPaginationChange = (page: number) => {
    setPaginationConfig({ ...paginationConfig, page: page });
  };

  /** Index table funcion definition */
  // const { tableData } = useContext(GridListActionContext);
  const { resourcesData } = useContext(GridListActionContext);

  useEffect(() => {
    if (filtersLoaded) {
      index();
    }
  }, [tableFilters, paginationConfig, filtersLoaded, orderBy]);

  useEffect(() => {
      // index();
  }, []);

  /**<newActionsFunctions>**/

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig(user);
  const filterConfig = {
    schema: getFiltersConfig(user),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  const goToResource = (url: string) => {
    console.log(url);
    window.open(url, '_blank');
  }

  const { downloadFunction } = functions;

  let actionTemplate =
    (
      <>
        {/* <BeelditTableFilters formConfig={filterConfig} /> */}
        <div className="resources-btn">
        {
          resourcesData.elements.map((mainResource: any) => {
            return (
                  <button className="btn btn-primary main-resource" onClick={() => {goToResource(mainResource.external_url)}}>{mainResource.name}</button>
            )
          })
        }
        </div>
      </>
    );
  /** END EDIT TABLE ACTION */

  return {
    indexFunctionMain,
    actionTemplate,
    setTableConfigMain,
  };
}

export default useGridListActionMain;
