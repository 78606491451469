import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}
interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function SaleForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    structure_type: t("structure_type"),    
    miniature: t("miniature"),
    document: t("document"),
    video_code: t("vide_code"),
  };

  let schema = {
    type: "object",
    properties: {
      name: {
        type: "string",
        title: translations.name,
      },
      type: {
        type: "number",
        default: 1, // Sale
        show: false,
      },
      structure_type: {
        type: "number",
        title: t('structure_type'),
        default: 0, // Document
        oneOf: [
          {
            title: "Document",
            const: 0,
          },
          {
            title: "Video",
            const: 1,
          }
        ],
      },      
      miniature: {
        type: "array",
        title: translations.miniature,
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        }
      },      
    },
    dependencies: {
      structure_type: {
        oneOf: [
          {
            properties: {
              structure_type: { enum: [0] },
              document: {
                type: "array",
                title: translations.document,
                items: {
                  type: "object",
                  properties: {
                    filename: { type: "string", title: "filename" },
                    url: { type: "string", title: "url" },
                  },
                }
              },
            },
          },
          {
            properties: {
              structure_type: { enum: [1] },
              video_code: {
                type: "string",
                title: t("video_code"),
                format: "textarea",
              }
            },
          },
        ],
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    miniature: {
      "ui:widget": "file-input",
    },
    document: {
      "ui:widget": "file-input",
    },
  };
  const customWidgets = {
    ...widgets,
    ...{
      "file-input": BeelditFileInput,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default SaleForm;
