import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [
    {
      name: TableAction.CREATE,
      label: "create_commercial",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: icon({ name: "plus", style: "solid" }),
      roles: ["admin"],
    },
  ],
  rowActions: [
    {
      name: RowAction.EDIT,
      label: "edit",
      icon: icon({ name: "pen-to-square", style: "solid" }),
      allowed: true,
      permissions: ["commercials.update"],
      show: true,
      action: (element: any) => {
        console.log("edit", element);
      },
    },
    {
      name: RowAction.DELETE,
      label: "delete",
      icon: icon({ name: "trash", style: "solid" }),
      allowed: true,
      show: true,
      permissions: ["commercials.destroy"],
      action: (element: any) => {
        console.log("delete", element);
      },
    }
  ],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [
    {
      name: "id",
      label: "#",
      key: "id",
      type: "number",
      show: true,
      sortable: true,
    },
    {
      name: "name",
      label: "name",
      key: "name",
      type: "name",
      show: true,
      sortable: true,
    }
  ],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getConfig: any = (functions: any) => {
  const {
    getFunction,
    indexFunction,
    initializeFunction,
    prepareRemoveFunction,
  } = functions;
  tableConfig.actions[0].action = () => initializeFunction();
  tableConfig.index = () => indexFunction();
  tableConfig.rowActions[0].action = (id: any) => getFunction(id);
  tableConfig.rowActions[1].action = (id: any) => prepareRemoveFunction(id);
  return tableConfig;
};
export default getConfig;
