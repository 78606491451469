import React, { createContext, useState } from "react";

interface DownloadPDFContextType {    
  element: any;
  setElement: (element: any) => void;  
}

export const DownloadPDFContext = createContext<DownloadPDFContextType>({    
  element: null,
  setElement: () => {},  
});

interface DownloadPDFProviderProps {
  children: React.ReactNode;
}

export const DownloadPDFProvider: React.FC<DownloadPDFProviderProps> = ({
  children,
}) => {    
  const [element, setElement] = useState<any>(null);  

  const value = {        
    element,
    setElement,    
  };
  return (
    <DownloadPDFContext.Provider value={value}>
      {children}
    </DownloadPDFContext.Provider>
  );
};
