import { useContext } from "react";
import { ImportActionContext } from "./ImportActionContext";

function useInitialize() {
  const { setElement, setShowModal, setBackendFormErrors } = useContext(ImportActionContext);
  return (formDefaultValues: any) => {
    setBackendFormErrors({});
    setElement(formDefaultValues);
    setShowModal(true);
  };
}

export default useInitialize;
