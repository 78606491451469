import { Pagination } from "react-bootstrap";
import { Fragment, useEffect, useState } from "react";
import { PaginationConfig } from "../models/PaginationConfig.interface";

interface Prop {
  paginationConfig: PaginationConfig;
  totalElements: number | undefined;
  onPaginationChange: any;
}

function BeelditTableLoadMorePagination(props: Prop) {
  const { paginationConfig, totalElements, onPaginationChange } = props;
  const [pages, setPages] = useState<any[]>([]);

  useEffect(() => {
    let pages = calculatePages(
      paginationConfig,
      totalElements ? totalElements : 0
    );
    setPages(pages);
  }, [paginationConfig, totalElements]);

  return (
    <div className="mt-5 load-more text-center">
      <button className="btn btn-primary" onClick={() => {onPaginationChange(paginationConfig.page + 1)}}>Cargar mas</button>
    </div>
  );
}

function calculatePages(
  paginationConfig: PaginationConfig,
  totalElements: number
) {
  let active = paginationConfig.page;
  let pages: any[] = [];
  let lastPage = calculateLastPage(paginationConfig, totalElements);
  for (let number = 1; number <= lastPage; number++) {
    pages.push({
      key: number,
      active: number === active,
      number: number,
    });
  }
  return pages;
}
function calculatePreviousPage(paginationConfig: PaginationConfig) {
  return Math.max(paginationConfig.page - 1, 1);
}
function calculateNextPage(
  paginationConfig: PaginationConfig,
  totalElements: number
) {
  return Math.min(
    paginationConfig.page + 1,
    calculateLastPage(paginationConfig, totalElements)
  );
}
function calculateLastPage(
  paginationConfig: PaginationConfig,
  totalElements: number
) {
  return Math.ceil(totalElements / paginationConfig.elementsPerPage);
}

export default BeelditTableLoadMorePagination;
