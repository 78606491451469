import { useContext, useEffect } from "react";
import useIndex from "./useIndex";
import { User } from "@beeldit/user-and-access/users/models/User";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import { GridListActionContext } from "./GridListActionContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import getFilterUIConfig from "@main/trainings/configs/filterUIConfig";
import getFiltersConfig from "@main/trainings/configs/filterConfig";
import { t } from "i18next";
import BeelditTableLoadMorePagination from "@beeldit/core/components/BeelditTableLoadMorePagination";
import BeelditTableFilters from "@beeldit/core/components/BeelditTableFilters";

function useGridListAction(user: any, filters: any = {}, functions: any = {}, withSearch: boolean = true): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();

  /** Crud functions */
  const index = useIndex();
  const indexFunction = () => index();

  /** Filter loading */
  const { filtersLoaded, setFiltersLoaded } = useContext(GridListActionContext);
  const { tableFilters, setTableFilters } = useContext(GridListActionContext);
  const { tableConfig, setTableConfig } = useContext(GridListActionContext);
  useFilters({ ...{}, ...filters }, GridListActionContext);

  /**Order by config */
  const { orderBy, setOrderBy } = useContext(GridListActionContext);

  const onOrderByChange = (orderBy: string) => {
    setOrderBy(orderBy);
  };

  /**
   * Pagination config
   */
  const { paginationConfig, setPaginationConfig } = useContext(
    GridListActionContext
  );

  const onPaginationChange = (page: number) => {
    setPaginationConfig({ ...paginationConfig, page: page });
  };

  /** Index table funcion definition */
  const { tableData } = useContext(GridListActionContext);

  useEffect(() => {
    if (filtersLoaded) {
      index();
    }
  }, [tableFilters, paginationConfig, filtersLoaded, orderBy]);

  /**<newActionsFunctions>**/

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig(user);
  const filterConfig = {
    schema: getFiltersConfig(user),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  const { downloadFunction, displayVideoFunction } = functions;

  let actionTemplate =
    filtersLoaded && tableConfig ? (
      <>
        {/* <BeelditTableFilters formConfig={filterConfig} /> */}
        {
          withSearch ?  <BeelditTableFilters formConfig={filterConfig} /> : null
        }  
        <div className="row row-cols- row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-4 mt-5">
          {tableData.elements.map((training: any) => {
            return (
              <div className="offer-card">
              <div className="recurso-formacion">
                <img
                    src={training.miniature[0].url}
                    alt={training.miniature[0].filename}
                    className="img-fluid"
                ></img>
                <div className="texts">
                <h4>{training.name}</h4>
                <p>{training.description}</p>
                </div>

                {
                  training.structure_type == 1 ?
                      <button className="btn btn-sm btn-primary" onClick={() => {displayVideoFunction(training.id)}}>{t("Ver")}</button>
                      :
                      <button className="btn btn-sm btn-primary" onClick={() => {downloadFunction(training.document[0].url)}}>{t("download")}</button>
                }
              </div>
              </div>
            );
          })}
        </div>
        <BeelditTableLoadMorePagination
          paginationConfig={paginationConfig}
          totalElements={tableData.totalElements}
          onPaginationChange={onPaginationChange}
        ></BeelditTableLoadMorePagination>
      </>
    ) : (
      <>
        Filters: {filtersLoaded.toString()} table config: {!tableConfig}
      </>
    );
  /** END EDIT TABLE ACTION */

  return {
    indexFunction,
    actionTemplate,
    setTableConfig,
  };
}

export default useGridListAction;
