import React, { createContext, useState } from "react";

interface KPIsActionContextType {    
  element: any;
  setElement: (element: any) => void;  
}

export const KPIsActionContext = createContext<KPIsActionContextType>({    
  element: null,
  setElement: () => {},  
});

interface KPIsActionProviderProps {
  children: React.ReactNode;
}

export const KPIsActionProvider: React.FC<KPIsActionProviderProps> = ({
  children,
}) => {  
  const [element, setElement] = useState<any>(null);  

  const value = {    
    element,
    setElement,    
  };
  return (
    <KPIsActionContext.Provider value={value}>      
      {children}
    </KPIsActionContext.Provider>
  );
};
