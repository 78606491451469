import React, { useContext, useEffect } from "react";
import getConfig from "../configs/tableConfig";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useGridListAction from "../actions/grid-list/useGridListAction";
import { Card } from "react-bootstrap";
import useDownloadAction from "../actions/download/useDownloadAction";
/**<newActionsImport>**/

function OffersGridWidget(props: any) {  
  const { user } = useContext(AuthContext);

  const { gridTitle } = props;
  
  const { downloadFunction } = useDownloadAction();

  const { indexFunction, actionTemplate: listGridActionTemplate, setTableConfig } = 
    useGridListAction(user, {type: '0'}, {downloadFunction}, false); // Offer    

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({    
    indexFunction, // We pass the function to index the table    
    /**newActionsTableConfig**/
  });
  useEffect(() => {
    setTableConfig(tableConfig);
  }, [tableConfig])

  return (
   <>     
           <div className="container mt-5">
             <h2 className="text-center">{gridTitle ? t(gridTitle) : t('offers')}</h2>
             {listGridActionTemplate}
           </div>
   </>
  );
}

export default OffersGridWidget;
