import React, { useContext, useEffect } from "react";
import getConfig from "../configs/communicationResourceTableConfig";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useGridListAction from "../actions/grid-list-communication-resource/useGridListAction";
import useGridListActionPet from "../actions/grid-list-communication-pet-resource/useGridListActionPet";
import useGridListActionMain from "../actions/grid-list-main-communication-resource/useGridListActionMain";
import { Card } from "react-bootstrap";
import useDownloadAction from "../actions/download/useDownloadAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
/**<newActionsImport>**/

function CommunicationResourcesGridPage() {
  const { user } = useContext(AuthContext);
  
  const { downloadFunction } = useDownloadAction();

  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;

  const { indexFunction, actionTemplate: listGridActionTemplate, setTableConfig } = 
    useGridListAction(user, {type: '3'}, {downloadFunction}); // CommunicationResource  

  const { indexFunctionPet, actionTemplate: listGridActionTemplatePet, setTableConfigPet } = 
    useGridListActionPet(user, {type: '3'}, {downloadFunction}); // CommunicationResource  

  const { indexFunctionMain, actionTemplate: listGridActionTemplateMain, setTableConfigMain } = 
    useGridListActionMain(user, {type: '3'}, {downloadFunction}); // CommunicationResource  

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let CommunicationResourceTableConfig = getConfig({    
    indexFunction, // We pass the function to index the table    
    /**newActionsTableConfig**/
  });
  let CommunicationPetResourceTableConfig = getConfig({    
    indexFunctionPet, // We pass the function to index the table    
    /**newActionsTableConfig**/
  });
  let CommunicationMainResourceTableConfig = getConfig({    
    indexFunctionMain, // We pass the function to index the table    
    /**newActionsTableConfig**/
  });
  useEffect(() => {
    setTableConfig(CommunicationResourceTableConfig);
  }, [CommunicationResourceTableConfig])

  useEffect(() => {
    setTableConfigPet(CommunicationResourceTableConfig);
  }, [CommunicationPetResourceTableConfig])

  useEffect(() => {
    setTableConfigMain(CommunicationResourceTableConfig);
  }, [CommunicationMainResourceTableConfig])

  return (
      <>
        <section className="hero not-full" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>
          <div className="container">
            <div className="hero-content">
              <h1 className="hero-title xl">Recursos de comunicación</h1>
              <p className="hero-description">Accede a materiales comerciales y de comunicación: imágenes y creatividades para tu web y social media.</p>
            </div>
            <div className="actions">
            <a href="/pharmacy/panel/commercial-resources" className="btn">Recursos comerciales <FontAwesomeIcon icon={faArrowRight} /></a>
            </div>
          </div>
        </section>
        <div className="px-5 py-3">
          {listGridActionTemplateMain}
        </div>
        <div className="container mt-5 mb-5">
          <h2 id="botanica-pharma" className="section-title">BotánicaPharma <FontAwesomeIcon icon={faArrowRight} /></h2>
          {listGridActionTemplate}
        </div>
        <div className="container mt-5 mb-5">
        <h2 id="botanica-pets" className="section-title">BotánicaPets <FontAwesomeIcon icon={faArrowRight} /></h2>
          {listGridActionTemplatePet}
        </div>
      </>
  );
}

export default CommunicationResourcesGridPage;
