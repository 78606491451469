const filterConfig = {
  type: 'object',
  properties: {
      level_id: {
        type: 'integer'
      },
      search: {
          type: 'string',
      },
    }
}
const getFiltersConfig: any = (type: string) => {
  return filterConfig;
}
export default getFiltersConfig;