import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
    }
  },
  es: {
    translation: {
      "pharmacies": "Farmacias",
      "offers": "Ofertas",
      "offer": "Oferta",
      "name": "Nombre",
      "pharmacy_name": "Nombre de la farmacia",
      "description": "Descripción",
      "start_datetime": "Fecha de inicio",
      "end_datetime": "Fecha de fin",
      "miniature": "Imagen miniatura",
      "document": "Documento",
      "Actions": "Acciones",
      "create_offer": "Crear oferta",
      "edit_offer": "Editar oferta",
      "remove_offer": "Eliminar oferta",
      "are_you_shure_you_want_to_delete_this_offer?": "¿Estás seguro de que quieres eliminar esta oferta?",
      "trainings": "Formación",
      "structure_type": "Tipo",
      "video_code": "Código de video",
      "created_at": "Fecha de creación",
      "create_training": "Crear formación",
      "edit_training": "Editar formación",
      "remove_training": "Eliminar formación",
      "are_you_shure_you_want_to_delete_this_training?": "¿Estás seguro de que quieres eliminar esta formación?",
      "commercial_resources": "Recursos comerciales",      
      "create_commercial_resource": "Crear recurso comercial",      
      "edit_commercial_resource": "Editar recurso comercial",
      "communication_resources": "Recursos de comunicación",
      "create_communication_resource": "Crear recurso de comunicación",
      "edit_communication_resource": "Editar recurso de comunicación",
      "remove_resource": "Borrar recurso",
      "are_you_shure_you_want_to_delete_this_resource?": "¿Estás seguro de que quieres eliminar este recurso?",
      "resources": "Recursos",
      "external_url": "URL externa",
      "download": "Descargar",
      "sales": "Ventas",
      "external_ref": "Referencia Externa",
      "amount": "Total",
      "date": "Fecha",
      "fiscal_name":"Nombre fiscal",
      "cif": "CIF/DNI",
      "contact_email": "Email de contacto",
      "contact_phone": "Teléfono",
      "contact_mobile_phone": "Teléfono móvil",
      "address": "Dirección",
      "postal_code": "Código postal",
      "rrss": "Redes Sociales",
      "city": "Ciudad",
      "state": "Provincia",
      "level": "Tipo",
      "purchases_responsible_name": "Responsable de compras",
      "latitude": "Latitud",
      "longitude": "Longitud",
      "last_incentive": "Usuarios canjeados",
      "comercial": "Comercial",
      "instagram": "Instagram",
      "balance": "Saldo",
      "none": "Ninguno",
      "balance_movements": "Movimientos de saldo",
      "create_balance_movement": "Crear movimiento de saldo",
      "concept": "Concepto",
      "pharmacy_id": "Farmacia",
      "sale_id": "Venta",
      "type": "Tipo",
      "edit_balance_movement": "Editar movimiento de saldo",
      "are_you_shure_you_want_to_delete_this_balance_movement?": "¿Estás seguro de que quieres eliminar este movimiento de saldo?",
      "remove_balance_movement": "Eliminar movimiento de saldo",
      "are_you_shure_you_want_to_delete_this_pharmacy?": "¿Estás seguro de que quieres eliminar esta Farmacia?",
      "remove_pharmacy": "Eliminar farmacia",
      "edit_pharmacy": "Editar Farmacia",
      "create_pharmacy": "Crear Farmacia",
      "save_changes": "Guardar cambios",
      "sign_in": "Iniciar sesión",
      "email": "Email",
      "password": "Contraseña",
      "detail_balance_movement": "Detalle de movimiento de saldo",
      "go_to_resource": "Ver materiales",
      "structure_type_0": "Documento",
      "structure_type_1": "Video",
      "structure_type_2": "Enlace externo",
      "pharmacy_data": "Datos farmacia",
      "Drag 'n' drop some files here, or click to select files": "Arrastra y suelta algún archivos aquí, o haz clic para seleccionar archivos",
      "export_pharmacies": "Exportar farmacias",
      "my_account": "Mi perfil",
      "exit": "Salir",
      "password_confirmed": "Confirmar contraseña",
      "change_password": "Cambiar contraseña",
      "password_changing": "Cambio de contraseña",
      "email_or_user": "CIF/DNI o email",
      "email_or_user_placeholder": "CIF/DNI de tu farmacia o email",
      "password_placeholder": "Contraseña",
      "reset_password": "Recuperar contraseña",
      "forgot_password": "¿Has olvidado tu contraseña?",
      "invalid_credential": "Usuario y/o contraseña inválida",
      "invalid_email": "Email o usuario incorrecto",
      "sent_reset_password": "Se ha enviado un correo electrónico con las instrucciones para restablecer la contraseña.",
      "invalid_token": "Token inválido",
      "account_details": "Detalles de la cuenta",
      "home": "Inicio",
      "last_offers": "Ofertas activas",
      "close": "Cerrar",
      "all_levels": "Todos los tipos",
      "all_states": "Todas las provincias",
      "main_resource": "Recurso carpeta principal",      
      'complete_form': 'Completa este formulario',
      'pharmacy': 'Farmacia',
      'phone': 'Teléfono',
      'subject': 'Asunto',
      'message': 'Mensaje',
      'send': 'Enviar',
      'contact': 'Contacto',
      'contact_type': 'Tipo de consulta',
      'commercials': 'Comerciales',
      'create_commercial': 'Crear comercial',
      'edit_commercial': 'Editar comercial',
      'remove_commercial': 'Eliminar comercial',
      'are_you_shure_you_want_to_delete_this_commercial?': '¿Estás seguro de que quieres eliminar este comercial?',
      'logo': 'Logotipo',      
      'code_name': 'Nombre',
      'percentage': 'Porcentaje',
      'create_level': 'Crear Tipo',
      'edit_level': 'Editar Tipo',
      'remove_level': 'Eliminar Tipo',
      "are_you_shure_you_want_to_delete_this_level?": "¿Estás seguro de que quieres eliminar este Tipo?",
      "levels": "Tipos",
      'file': 'Archivo',
      'import_pharmacies': 'Importar Farmacias',
      "web": "Web",
      "profile": "Perfil",
      "request_training": "Solicitar formación",
      "request_commercial_visit": "Solicitar visita comercial",
      "doubts_queries" : "Dudas y/o consultas",
      "category": "Categoría",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;