import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t, use } from "i18next";
import { Button } from "react-bootstrap";
import useProfileAction from "../actions/profile/useProfileAction";
import { ProfileActionContext } from "../actions/profile/ProfileActionContext";
import { Pharmacy } from "../models/Pharmacy";

function PharmacyProfilePage() {
  const { user } = useContext(AuthContext);

  const { editionMode, setEditionMode, setElement } = useContext(ProfileActionContext);

  const {
    onSubmitFunction,
    actionTemplate: profileActionTemplate,
  } = useProfileAction();  

  let pharmacy: Pharmacy = new Pharmacy();

  useEffect(() => {
    if (user) {
      pharmacy = user.extra_data.pharmacy;
      setElement(pharmacy);
    }    
    
  }, [user]);
  

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        {pharmacy ? <h3>{pharmacy.name}</h3> : null}
        {editionMode ? (
          <Button onClick={() => setEditionMode(false)}>
            {t("Ver cuenta")}
          </Button>
        ) : (
          <Button onClick={() => setEditionMode(true)}>
            {t("Editar cuenta")}
          </Button>
        )}
      </div>

      {profileActionTemplate}
      <div className="text-end">
        {editionMode ? (
          <Button variant="outline-primary" onClick={onSubmitFunction}>
            {t("save_changes")}
          </Button>
        ) : null}
      </div>
    </>
  );
}

export default PharmacyProfilePage;
