import { useContext, useEffect } from "react";
import useIndex from "./useIndex";
import { User } from "@beeldit/user-and-access/users/models/User";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import { GridListActionContext } from "./GridListActionContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import getFilterUIConfig from "@main/offers/configs/filterUIConfig";
import getFiltersConfig from "@main/offers/configs/filterConfig";
import { t } from "i18next";
import BeelditTableLoadMorePagination from "@beeldit/core/components/BeelditTableLoadMorePagination";
import BeelditTableFilters from "@beeldit/core/components/BeelditTableFilters";

function useGridListAction(user: any, filters: any = {}, functions: any = {}, withSearch: boolean = true): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();

  /** Crud functions */
  const index = useIndex();
  const indexFunction = () => index();

  /** Filter loading */
  const { filtersLoaded, setFiltersLoaded } = useContext(GridListActionContext);
  const { tableFilters, setTableFilters } = useContext(GridListActionContext);
  const { tableConfig, setTableConfig } = useContext(GridListActionContext);
  useFilters({ ...{}, ...filters }, GridListActionContext);

  /**Order by config */
  const { orderBy, setOrderBy } = useContext(GridListActionContext);

  const onOrderByChange = (orderBy: string) => {
    setOrderBy(orderBy);
  };

  /**
   * Pagination config
   */
  const { paginationConfig, setPaginationConfig } = useContext(
    GridListActionContext
  );

  const onPaginationChange = (page: number) => {
    setPaginationConfig({ ...paginationConfig, page: page });
  };

  /** Index table funcion definition */
  const { tableData } = useContext(GridListActionContext);

  useEffect(() => {
    if (filtersLoaded) {
      index();
    }
  }, [tableFilters, paginationConfig, filtersLoaded, orderBy]);

  /**<newActionsFunctions>**/

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig(user);
  const filterConfig = {
    schema: getFiltersConfig(user),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  const { downloadFunction } = functions;

  let actionTemplate =
    filtersLoaded && tableConfig ? (
      <>
        {
          withSearch ?  <BeelditTableFilters formConfig={filterConfig} /> : null
        }        
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 mt-5">
          {tableData.elements.map((offer: any) => {
            return (
              <div className="offer-card">
                <div className="offer-card-image text-center">
                  <img
                    src={offer.miniature[0].url}
                    alt={offer.miniature[0].filename}
                    className="img-fluid"
                  ></img>
                  <div className="overlay">
                    {/* <p className="offer-card-description">{offer.description}</p> */}
                    <button className="btn btn-primary" onClick={() => {downloadFunction(offer.document[0].url)}}>{t("download")}</button>
                  </div>
                </div>
                <div>
                    <h4>{offer.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <BeelditTableLoadMorePagination
          paginationConfig={paginationConfig}
          totalElements={tableData.totalElements}
          onPaginationChange={onPaginationChange}
        ></BeelditTableLoadMorePagination>
      </>
    ) : (
      <>
        Filters: {filtersLoaded.toString()} table config: {!tableConfig}
      </>
    );
  /** END EDIT TABLE ACTION */

  return {
    indexFunction,
    actionTemplate,
    setTableConfig,
  };
}

export default useGridListAction;
