import { useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';

interface RouterRoleGuardProps {
  children: ReactNode;
  allowedRoles: string[];
}

function RouterRoleGuard({ children, allowedRoles }: RouterRoleGuardProps) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  // Si el rol del usuario no está entre los roles permitidos, redirige.
  if (!user || !allowedRoles.includes(user.role)) {
    navigate('/login');
    return null;
  }

  return <>{children}</>;
}

export default RouterRoleGuard;
