import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import get from "@beeldit/core/services/get";
import { KPIsActionContext } from "./KPIsActionContext";
import { getApi } from "@beeldit/core/helpers/api";

function useKPIs() {
  const { setElement } = useContext(KPIsActionContext);
  const errorHandler = useErrorHandler();

  return (
    id: any    
  ) => {
    getApi()
      .get(`/pharmacies/${id}/kpis`)
      .then((response: any) => {
        let data = response.data;        
        setElement(data);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useKPIs;
