import React, { createContext, useState } from "react";

interface EditActionContextType {  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;  
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void; 
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export const EditActionContext = createContext<EditActionContextType>({  
  backendFormErrors: {},
  setBackendFormErrors: () => {},
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},
  loading: false,
  setLoading: () => {},
});

interface EditActionProviderProps {
  children: React.ReactNode;
}

export const EditActionProvider: React.FC<EditActionProviderProps> = ({
  children,
}) => {  
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const value = {    
    backendFormErrors,
    setBackendFormErrors,
    showModal,
    setShowModal,
    element,
    setElement,
    loading,
    setLoading   
  };
  return (
    <EditActionContext.Provider value={value}>      
      {children}
    </EditActionContext.Provider>
  );
};
