import React, { useContext, useEffect } from "react";
import getConfig from "../configs/tableConfig";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useGridListAction from "../actions/grid-list/useGridListAction";
import { Card } from "react-bootstrap";
import useDownloadAction from "../actions/download/useDownloadAction";
import useDisplayVideoAction from "../actions/display-video/useDisplayVideoAction";
/**<newActionsImport>**/

function TrainingsGridPage() {
  const { user } = useContext(AuthContext);
  
  const { downloadFunction } = useDownloadAction();

  const { displayVideoFunction, actionTemplate: displayVideoActionTemplate } = useDisplayVideoAction();
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  const { indexFunction, actionTemplate: listGridActionTemplate, setTableConfig } = 
    useGridListAction(user, {type: '1'}, {downloadFunction, displayVideoFunction}); // Training  

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({    
    indexFunction, // We pass the function to index the table    
    /**newActionsTableConfig**/
  });
  useEffect(() => {
    setTableConfig(tableConfig);
  }, [tableConfig])

  return (
      <>
        <section className="hero not-full" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>
          <div className="container">
            <div className="hero-content">
              <h1 className="hero-title xl">Formación</h1>
              <p className="hero-description">Conoce en profundidad cada uno de nuestros productos con formaciones específicas.</p>
            </div>
          </div>
        </section>
        <div className="container mt-5 mb-5">
          {listGridActionTemplate}
          {displayVideoActionTemplate}
        </div>
      </>
  );
}

export default TrainingsGridPage;
