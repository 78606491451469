import React, { useContext, useEffect } from "react";
import getConfig from "../configs/tableConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useRemoveAction from "../actions/remove/useRemoveAction";
import useCreateAction from "../actions/create/useCreateAction";
import useEditAction from "../actions/edit/useEditAction";
import useListAction from "../actions/list/useListAction";
import useDetailsAction from "../actions/details/useDetailsAction";
import { Card } from "react-bootstrap";
/**<newActionsImport>**/

function BalanceMovementsPharmacyPage(props: any) {
  const { filters, formDefaultValues } = props;
  
  const { user } = useContext(AuthContext);
  

  const { indexFunction, actionTemplate: listActionTemplate, setTableConfig } = 
    useListAction(user, filters); // BalanceMovement

  const { initializeFunction, actionTemplate: createActionTemplate } =
    useCreateAction(indexFunction, formDefaultValues);

  const { getFunction, actionTemplate: editActionTemplate } =
    useEditAction(indexFunction);

  const { prepareRemoveFunction, actionTemplate: removeActionTemplate } =
    useRemoveAction(indexFunction);


    const { detailsFunction, actionTemplate: detailActionTemplate } = useDetailsAction(0);
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    initializeFunction, // We pass the function to initialize a new element (used in the create button)
    indexFunction, // We pass the function to index the table
    getFunction, // We pass the function to get an element (used in the edit button)
    prepareRemoveFunction,  
    detailsFunction,  
    /**newActionsTableConfig**/
  });
  useEffect(() => {
    setTableConfig(tableConfig);
  }, [tableConfig])

  return (
    <>
      <section className="hero not-full" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>
        <div className="container">
          <div className="hero-content">
            <h1 className="hero-title xl">Movimientos de Saldo</h1>
            <p className="hero-description">Consulta todos los descuentos aplicados a tus facturas por pertenecer a The Good Society.</p>
          </div>
        </div>
      </section>
      <div className="container">
      <div className="row">
        <div className="page-content p-3 pharmacy">
          <Card>
            <Card.Body>
              {listActionTemplate}
              {createActionTemplate}
              {editActionTemplate}
              {removeActionTemplate}
              {detailActionTemplate}
              {/* newTemplateActions */}
            </Card.Body>
          </Card>
        </div>
      </div>
      </div>
    </>
  );
}

export default BalanceMovementsPharmacyPage;
