import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import axios from "axios";
import { t } from "i18next";
import sendForm from "../services/sendForm";

// let interceptorId: number | null = null;

interface Prop {
}

const ContactForm = (props: Prop) => {
  let { typeId } = useParams();
  if (!typeId) {
    typeId = "1";
  }
  const types = [
    {title:"Formación presencial u on-line", type: 1},
    {title:"Visita de un comercial", type: 2},
    {title:"Dudas y/o consultas", type: 3},
  ];
  const [backendErrors, setBackendErrors] = useState({name: [], email: [], subject:[], message:[], phone:[]});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState((types.some(typeObject => typeObject['type'].toString() === typeId))? parseInt(typeId) : types[0].type);
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;

  useEffect(() => {
    const anchor = window.location.hash.slice(1);
    if (anchor) {
        const anchorEl = document.getElementById(anchor);
        if (anchorEl) {
            anchorEl.scrollIntoView();
        }
    }
  }, []);


  const onSuccess = (data: any) => {
    clearForm();
    //TODO Alert?
  };

  const onError = (data: any) => {
    setBackendErrors(data.errors);
  }

  const handleContactForm = async (e: any) => {
    sendForm({email: email, name: name, type: type, phone: phone, subject: subject, message: message}, onSuccess, onError, setLoading)
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleContactForm(event);
    }
  };

  const clearForm = () => {
    setEmail('');
    setName('');
    setType(types[0].type);
    setPhone('');
    setSubject('') ;
    setMessage('');
  }

  const btnActive = 'btn px-3 mx-2 btn-primary';
  const btnInactive = 'btn px-3 mx-2 btn-secondary';

  return (
<>
  <section className="hero not-full" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>
    <div className="container">
      <div className="hero-content">
        <h1 className="hero-title xl">Contacto</h1>
        </div>
    </div>
  </section>
    <div
      className="d-flex flex-column align-items-center justify-content-center"
    >
     <div className="container mt-5 mb-5">
       <div className="row mb-2 align-items-center">
         <div className="col-12 col-md-6 px-5 mb-5">
           <img className="img-fluid" src="/contacto.webp" alt=""/>
         </div>
         <div className="col-12 col-md-6 px-5">
           <div className="contact-info ms-0 ms-md-5">
             <h2 className="mb-2 contact-title">Estamos en contacto</h2>
             <p>¿Cómo podemos ayudarte hoy? No dudes en ponerte en contacto con nosotros, te responderemos lo antes posible.</p>
              <p className="contact bold">Llámanos</p>
             <p className="contact">Te atendemos por teléfono: </p>
             <p className="contact">Lunes a Viernes de 8:00 a 19:00 horas.</p>
             <p className="contact">+34 955 266 263</p>
             <p className="contact bold">Envíamos un e-mail</p>
             <p className="contact">comercial@botanicapharma.com</p>
             <p className="contact bold">¿Eres una farmacia?</p>
             <p className="contact">comercial@botanicapharma.com</p>
           </div>
         </div>
       </div>
       <div id="contact-form" className="row pt-6">
         <div className="col-12 col-sm-10 offset-md-1">
           <h1 className="card-title text-center mb-4">{t("complete_form")}</h1>
           <div className="type-selector">
           <button onClick={() => setType(1)} className={(type === 1)? btnActive : btnInactive }>
           {t("request_training")}
          </button>
          <button onClick={() => setType(2)} className={(type === 2)? btnActive : btnInactive }>
          {t("request_commercial_visit")}
          </button>
          <button onClick={() => setType(3)} className={(type === 3)? btnActive : btnInactive }>
          {t("doubts_queries")}
          </button>
           </div>
           <form>
             <div className="mb-3">
               <label htmlFor="name" className="form-label">
                 {t("name")}:
               </label>
               <input
                   type="text"
                   className="form-control"
                   id="name"
                   value={name}
                   onChange={(e) => setName(e.target.value)}
                   onKeyPress={handleKeyPress}
               />
               {backendErrors && backendErrors.name ?
                   <div className="error-detail bs-callout bs-callout-inf">
                     <ul>
                       {backendErrors.name.map((error) => (
                           <li className="text-danger">{error}</li>
                       ))}
                     </ul>
                   </div> : null}

             </div>
             <div className="mb-3">
               <label htmlFor="phone" className="form-label">
                 {t("phone")}:
               </label>
               <input
                   type="text"
                   className="form-control"
                   id="phone"
                   value={phone}
                   onChange={(e) => setPhone(e.target.value)}
                   onKeyPress={handleKeyPress}
               />
               {backendErrors && backendErrors.phone ?
                   <div className="error-detail bs-callout bs-callout-inf">
                     <ul>
                       {backendErrors.phone.map((error) => (
                           <li className="text-danger">{error}</li>
                       ))}
                     </ul>
                   </div> : null}
             </div>
             <div className="mb-3">
               <label htmlFor="email" className="form-label">
                 {t("email")}:
               </label>
               <input
                   type="text"
                   className="form-control"
                   id="email"
                   value={email}
                   onChange={(e) => setEmail(e.target.value)}
                   onKeyPress={handleKeyPress}
               />
               {backendErrors && backendErrors.email ?
                   <div className="error-detail bs-callout bs-callout-inf">
                     <ul>
                       {backendErrors.email.map((error) => (
                           <li className="text-danger">{error}</li>
                       ))}
                     </ul>
                   </div> : null}
             </div>
             <div className="mb-3">
               <label htmlFor="subject" className="form-label">
                 {t("subject")}:
               </label>
               <input
                   type="text"
                   className="form-control"
                   id="subject"
                   value={subject}
                   onChange={(e) => setSubject(e.target.value)}
                   onKeyPress={handleKeyPress}
               />
               {backendErrors && backendErrors.subject ?
                   <div className="error-detail bs-callout bs-callout-inf">
                     <ul>
                       {backendErrors.subject.map((error) => (
                           <li className="text-danger">{error}</li>
                       ))}
                     </ul>
                   </div> : null}
             </div>
             <div className="mb-3">
               <label htmlFor="message" className="form-label">
                 {t("message")}:
               </label>
               <textarea
                   className="form-control"
                   id="message"
                   rows={8}
                   value={message}
                   onChange={(e) => setMessage(e.target.value)}
               />
               {backendErrors && backendErrors.message ?
                   <div className="error-detail bs-callout bs-callout-inf">
                     <ul>
                       {backendErrors.message.map((error) => (
                           <li className="text-danger">{error}</li>
                       ))}
                     </ul>
                   </div> : null}
             </div>
             <button
                 onClick={handleContactForm}
                 type="button"
                 className="btn btn-primary px-5 float-end"
                disabled={loading}
             >
               {t("send")}
             </button>
           </form>
         </div>
       </div>
     </div>
    </div>
</>
  );
};

export default ContactForm;
