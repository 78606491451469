import React, { createContext, useState, useContext } from "react";
import { PaginationConfig } from "@beeldit/core/models/PaginationConfig.interface";
import { TableData } from "@beeldit/core/models/TableData.interface";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

interface GridListActionContextType {
  filtersLoaded: boolean;
  setFiltersLoaded: (filtersLoaded: boolean) => void;
  tableFilters: any;
  setTableFilters: (tableFilters: any) => void;
  paginationConfig: PaginationConfig;
  setPaginationConfig: (paginationConfig: PaginationConfig) => void;
  tableData: TableData;
  setTableData: (tableData: TableData) => void;
  orderBy: string | null;
  setOrderBy: (orderBy: string | null) => void;  
  tableConfig: FinalTableConfig;
  setTableConfigPet: (tableConfig: FinalTableConfig) => void;
}

export const GridListActionContext = createContext<GridListActionContextType>({
  filtersLoaded: false,
  setFiltersLoaded: () => {},
  tableFilters: {},
  setTableFilters: () => {},
  paginationConfig: { page: 1, elementsPerPage: 12 },
  setPaginationConfig: () => {},
  tableData: { elements: [] },
  setTableData: () => {},
  orderBy: null,
  setOrderBy: () => {},
  tableConfig: { index: () => {}, actions: [], rowActions: [], rowConfiguration: {}, columns: [], pagination: { enabled: false, back: false }},
  setTableConfigPet: () => {},
});

interface GridListActionProviderProps {
  children: React.ReactNode;
}

export const GridListActionProvider: React.FC<GridListActionProviderProps> = ({
  children,
}) => {
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [tableFilters, setTableFilters] = useState({});
  const [paginationConfig, setPaginationConfig] = useState<PaginationConfig>({
    page: 1,
    elementsPerPage: 12,
  });
  const [tableData, setTableData] = useState<TableData>({ elements: [] });
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [tableConfig, setTableConfigPet] = useState<FinalTableConfig>({
    index: () => {},
    actions: [],
    rowActions: [],
    rowConfiguration: {},
    columns: [],

    pagination: {
      enabled: false,
      back: false,
    },
  });

  const value = {
    filtersLoaded,
    setFiltersLoaded,
    tableFilters,
    setTableFilters,
    paginationConfig,
    setPaginationConfig,
    tableData,
    setTableData,
    orderBy,
    setOrderBy,
    tableConfig,
    setTableConfigPet,
  };
  return (
    <GridListActionContext.Provider value={value}>
      {children}
    </GridListActionContext.Provider>
  );
};
