import { createRef, useContext } from "react";
import JSONSchemaForm from "@rjsf/core";
import useGet from "./useGet";
import useUpdate from "./useUpdate";
import { ProfileActionContext } from "./ProfileActionContext";
import PharmacyProfileForm from "@main/pharmacies/components/PharmacyProfileForm";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";

function useProfileAction(): any {
  const { setEditionMode } = useContext(ProfileActionContext);

  const {user, setUser} = useContext(AuthContext);
  const onSuccessGet = (data: any) => {   
    if(!user) return;
    let newUser = {...user, extra_data: {...user.extra_data, pharmacy: data}};
    setUser(newUser);
    localStorage.setItem("user", JSON.stringify(newUser));
  }

  const get = useGet();
  const getFunction = (id: number) => get(id, onSuccessGet);

  /**
   * Form store success function definition
   */
  const onSuccessUpdate = (id: any) => {
    getFunction(id); // We pass the function to refresh the table on success
    setEditionMode(false);
  };

  const update = useUpdate();
  const updateFunction = () => update(onSuccessUpdate);

  /** Edition element definition */
  const { element } = useContext(ProfileActionContext);

  /**
   * Form Reference definition
   */
  const formRef = createRef<JSONSchemaForm>();

  const onSubmitFunction = () =>
    formRef.current?.formElement.current.requestSubmit(); //We pass the function for the submit of the modal

  /**
   * Form configuration definition
   */
  const formConfig = {
    formRef: formRef, // We pass the ref to the form
    onSubmit: () => {
      updateFunction();
    },
    element: element, // We pass the element to the form in order to render it in the modal form
    context: ProfileActionContext,
    className: "pharmacy-profile-form",
  };

  const actionTemplate = <PharmacyProfileForm formConfig={formConfig} />;

  /** END EDIT FORM ACTION */

  return {
    getFunction,
    onSubmitFunction,
    actionTemplate,
  };
}

export default useProfileAction;
