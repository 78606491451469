import {KPIsActionProvider} from "@main/pharmacies/actions/kpis/KPIsActionContext";
import PharmacyKPIsPage from "@main/pharmacies/pages/PharmacyKPIsPage";
import {GridListActionProvider as GridOfferListActionProvider} from "../offers/actions/grid-list/GridListActionContext";
import OffersGridPage from "@main/offers/pages/OffersGridPage";
import {AuthContext} from "@beeldit/user-and-access/auth/AuthContext";
import {useContext, useEffect} from "react";
import {Pharmacy} from "@main/pharmacies/models/Pharmacy";
import OffersGridWidget from "@main/offers/widgets/OffersGridWidget";

function HomePage() {

    const {user} = useContext(AuthContext);

    if (!user) {
        return null;
    }

    const pharmacy = user.extra_data.pharmacy;
    const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
    const filters = {pharmacy_id: parseInt(pharmacy.id)};

    return (
        <>
            <section className="hero" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>
                <div className="container">
                    <div className="hero-content">
                        <h1 className="hero-title">¡HOLA {pharmacy.name}!</h1>
                        <h2 className="hero-title">¡Te damos la bienvenida a</h2>
                        <h2 className="hero-title">The Good Society!</h2>
                        <p className="hero-description">Visualiza aquí todos tus beneficios actualizados, 
                        y accede a ofertas y recursos para ayudarte en tu recomendación</p>
                        <div className="hero-buttons-index">
                            <a href="#sectionkpis" className="btn ">Ver recompensas</a>
                            <a href="#sectionoffers" className="btn outline">Ofertas</a>

                        </div>
                    </div>
                </div>
            </section>
            <div id="sectionkpis" className="container mt-5">
                <div className="row align-items-start">
                    <div className="col-12 col-md-3 mt-2">
                        <h2>Programa de recompensas</h2>
                        <p>Sigue sumando usuarios suscritos a tu farmacia y verás crecer tus beneficios.</p>
                    </div>
                    <div className="col-12 col-md-9 kpy-parent">
                        <KPIsActionProvider>
                            <PharmacyKPIsPage filters={filters}></PharmacyKPIsPage>
                        </KPIsActionProvider>
                    </div>
                </div>
            </div>
            <section id="sectionoffers" className="offers b-5">
                <div className="container container-offer">
                    <div className="row">
                        <div>
                            <GridOfferListActionProvider>
                                <OffersGridWidget gridTitle="last_offers"/>
                            </GridOfferListActionProvider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomePage;
