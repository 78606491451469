import { useContext } from "react";
import getConfig from "../configs/tableConfig";
import BeelditTableHeaderActions from "@beeldit/core/components/BeelditTableHeaderActions";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useRemoveAction from "../actions/remove/useRemoveAction";
import useCreateAction from "../actions/create/useCreateAction";
import useEditAction from "../actions/edit/useEditAction";
import useListAction from "../actions/list/useListAction";
import { Card } from "react-bootstrap";
import useDetailsAction from "../actions/details/useDetailsAction";

function LevelsPage(props: any) {
  const { filters, formDefaultValues } = props;

  const { user } = useContext(AuthContext);

  const {
    indexFunction,
    actionTemplate: listActionTemplate,
    setTableConfig,
  } = useListAction(user, filters);

  const { initializeFunction, actionTemplate: createActionTemplate } =
    useCreateAction(indexFunction, formDefaultValues);

  const { getFunction, actionTemplate: editActionTemplate } =
    useEditAction(indexFunction);

  const { prepareRemoveFunction, actionTemplate: removeActionTemplate } =
    useRemoveAction(indexFunction);

  const { detailsFunction } = useDetailsAction(0);


  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    initializeFunction, // We pass the function to initialize a new element (used in the create button)
    indexFunction, // We pass the function to index the table
    getFunction, // We pass the function to get an element (used in the edit button)
    prepareRemoveFunction,
    detailsFunction,
    /**newActionsTableConfig**/
  });
  setTableConfig(tableConfig);

  return (
    <Card>
      <Card.Header className="d-flex align-items-center justify-content-between ps-3 bg-white">
        <span>{t("levels")}</span>
        <div>
          <BeelditTableHeaderActions
            actions={tableConfig.actions}
          ></BeelditTableHeaderActions>
        </div>
      </Card.Header>

      <Card.Body>
        {listActionTemplate}
        {createActionTemplate}
        {editActionTemplate}
        {removeActionTemplate}
        {/* newTemplateActions */}
      </Card.Body>
    </Card>
  );
}

export default LevelsPage;
