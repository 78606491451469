import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import download from "@beeldit/core/services/download";

function useDownload() {  
  const errorHandler = useErrorHandler();

  return (url: string, onSuccess: any) => {
    download("files/download", {url})
      .then((response: any) => {
        let filename = url.split("/").pop();
        onSuccess(response.data, filename);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useDownload;
