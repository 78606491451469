import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useContext } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import { t } from "i18next";
import AdminAppNavbar from "./AdminAppNavbar";
import '../css/custom.css';

function PharmacyAdminPanel() {
  const { tokenLoaded, user } = useContext(AuthContext);

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  if (!tokenLoaded || !user) {
    return <div>Loading...</div>;
  }

  const items: any[] = [
    {
      label: t("pharmacies"),
      to: `/admin/panel/pharmacies`,
      icon: icon({ name: "clinic-medical", style: "solid" }),
    },
    {
      label: t("levels"),
      to: `/admin/panel/levels`,
      icon: icon({ name: "coins", style: "solid" }),
    },
    {
      label: t("offers"),
      to: `/admin/panel/offers`,
      icon: icon({ name: "star", style: "solid" }),
    },
    {
      label: t("trainings"),
      to: `/admin/panel/trainings`,
      icon: icon({ name: "graduation-cap", style: "solid" }),
    },
    {
      label: t("resources"),
      to: `/admin/panel/resources`,
      icon: icon({ name: "folder", style: "solid" }),
    },
    {
        label: t("commercials"),
        to: `/admin/panel/commercials`,
        icon: icon({ name: "users", style: "solid" }),
    },
  ];

  return (
    <div id="admin-panel">
      <AdminAppNavbar items={items} />
      <div className="admin container-fluid">
        <div className="row">
          <Sidebar items={items} />
          <div className="page-content p-3">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PharmacyAdminPanel;
