import useDownload from "./useDownload";

function useDownloadAction(): any {
  const onSuccessDownload = (file: any, filename: any) => {
    const blob = new Blob([file], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);

    // Crear un enlace temporal para descargar el archivo
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); // o cualquier otro nombre de archivo
    document.body.appendChild(link);
    link.click();

    // Eliminar el enlace temporal cuando se haya descargado el archivo
    if (link && link.parentNode) {
      link.parentNode.removeChild(link);
    }
  };

  const download = useDownload();
  const downloadFunction = (url: string) => {
    download(url, onSuccessDownload);
  };

  return {
    downloadFunction,
  };
}

export default useDownloadAction;
