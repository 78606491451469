import useExport from "./useExport";

function useExportAction(filters: any = {}): any {
  const toExport = useExport();
  const exportFunction = () => toExport();

  return {
    exportFunction,
  };
}

export default useExportAction;
