import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { customizeValidator } from "@rjsf/validator-ajv8";
import JSONSchemaForm from "@rjsf/core";
import { useTranslation } from "react-i18next";
import { MouseEventHandler, useContext, useEffect, useState } from "react";
import customFormats from "@beeldit/core/custom-validations/custom-validation";
import useProcessSchema from "@beeldit/core/hooks/useProcessSchema";
import widgets from "@beeldit/core/widgets/widgets";
import BeelditFileInput from "@beeldit/core/components/BeelditFileInput";

interface Prop {
  formConfig: {
    formRef: any;
    onSubmit: any;
    context: any;
  };
}
interface Context {
  element: any;
  setElement: (element: any) => MouseEventHandler;
  backendFormErrors: any;
}

function OfferContentForm(props: Prop) {
  const { formRef, onSubmit, context } = props.formConfig;
  const { element, setElement, backendFormErrors } =
    useContext<Context>(context);

  const { t } = useTranslation();

  const [finalSchema, setFinalSchema] = useState<RJSFSchema>({});

  const translations = {
    name: t("name"),
    start_datetime: t("start_datetime"),
    end_datetime: t("end_date"),
    miniature: t("miniature"),
    document: t("document"),
    description: t("description"),
  };

  let schema = {
    type: "object",
    properties: {
      name: {
        type: "string",
        title: translations.name + '*',
      },
      type: {
        type: "number",
        default: 0, // Offer
        show: false,
      },
      start_datetime: {
        type: "string",
        format: "datetime",
        title: translations.start_datetime + '*',
      },
      end_datetime: {
        type: "string",
        format: "datetime",
        title: translations.end_datetime + '*',
      },
      miniature: {
        type: "array",
        description: "(Imagen de la oferta en formato png o jpg)",
        title: translations.miniature + '*',
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        }
      },
      document: {
        type: "array",
        title: translations.document + '*',
        description: "(Documento de la oferta en formato pdf)",
        items: {
          type: "object",
          properties: {
            filename: { type: "string", title: "filename" },
            url: { type: "string", title: "url" },
          },
        }
      },
    },
  };
  const uiSchema: UiSchema = {
    "ui:submitButtonOptions": {
      props: {
        disabled: false,
        className: "btn btn-info",
      },
      norender: true,
    },
    miniature: {
      "ui:widget": "file-input",
    },
    document: {
      "ui:widget": "file-input",
    },
    name: {
      "ui:placeholder": translations.name,
    }
  };
  const customWidgets = {
    ...widgets,
    ...{
      "file-input": BeelditFileInput,
    },
  };
  const handleChange = (type: any) => {
    /** Este método de momento es necesario aunque en realidad no tenga sentido, pero se debe a un bug de la libreria */
    // Actualizar el estado solo si no hay errores de validación
    setElement(type.formData);
    return console.log.bind(console, type);
  };
  const log = (type: any) => {
    return console.log.bind(console, type);
  };

  const formats = customFormats;
  const validator = customizeValidator({ customFormats: formats });

  const processSchemaFunction = useProcessSchema();

  useEffect(() => {
    setFinalSchema(processSchemaFunction(schema, element));
  }, [element]);

  return (
    <JSONSchemaForm
      noHtml5Validate
      showErrorList={false}
      ref={formRef}
      schema={finalSchema}
      uiSchema={uiSchema}
      widgets={customWidgets}
      formData={element}
      validator={validator}
      onChange={handleChange}
      onSubmit={onSubmit}
      onError={log("errors")}
      extraErrors={backendFormErrors}
    />
  );
}

export default OfferContentForm;
