import React, { useContext, useEffect } from "react";
import getConfig from "../configs/tableConfig";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useGridListAction from "../actions/grid-list/useGridListAction";
import { Card } from "react-bootstrap";
import useDownloadAction from "../actions/download/useDownloadAction";
/**<newActionsImport>**/

function OffersGridPage(props: any) {
  const { user } = useContext(AuthContext);

  const { gridTitle } = props;

  const { downloadFunction } = useDownloadAction();

  const {
    indexFunction,
    actionTemplate: listGridActionTemplate,
    setTableConfig,
  } = useGridListAction(user, { type: "0" }, { downloadFunction }); // Offer
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;

  /**<newActionsHooks**/

  /**
   * Table configuration definition
   */
  let tableConfig = getConfig({
    indexFunction, // We pass the function to index the table
    /**newActionsTableConfig**/
  });
  useEffect(() => {
    setTableConfig(tableConfig);
  }, [tableConfig]);

  return (
    <>
      <section
        className="hero not-full"
        style={{ backgroundImage: "url(/" + backgroundSrc + ")" }}
      >
        <div className="container">
          <div className="hero-content">
            <h1 className="hero-title xl">{t("offers")}</h1>
            <p className="hero-description">Descubre qué ofertas tenemos hoy para tí.</p>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="page-content p-3">
            <div className="container mt-5">
              {listGridActionTemplate}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OffersGridPage;
