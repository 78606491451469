import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

const tableConfig: FinalTableConfig = {
      index: null,
      actions: [
        {
          name: TableAction.REFRESH, 
          label: 'refresh',
          allowed: true,
          show: true,
          action: null,
          icon: icon({name: 'refresh', style: 'solid'}),
        },

      ],
      rowActions: [

      ],
      rowConfiguration: {
        class: (element: any) => {
          return null;
        }
      },
      columns: [
        {
          name: 'id',
          label: '#',
          key: 'id',
          type: 'number',
          show: true,
          allowOrder: true,
        },
        {
          name: 'external_ref',
          label: 'external_ref',
          key: 'external_ref',
          type: 'string',
          show: true,
          allowOrder: false,
        },
        {
          name: 'amount',
          label: 'amount',
          key: 'amount',
          type: 'number',
          show: true,
          allowOrder: false,
        },
        {
          name: 'date',
          label: 'date',
          key: 'date',
          type: 'date',
          show: true,
          allowOrder: false,
        }
      ],
      pagination: {
        enabled: true,
        back: true
    }
}
const getConfig: any = (functions: any) => {
    const { indexFunction } = functions;

    tableConfig.index = () => indexFunction();
    return tableConfig;
}
export default getConfig;