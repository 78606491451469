import { useContext } from "react";
import useGet from "./useGet";
import { DisplayVideoActionContext } from "./DisplayVideoActionContext";
import { Button, Modal } from "react-bootstrap";

function useDisplayVideoAction(): any {
  const get = useGet();
  const displayVideoFunction = (id: number) => get(id);

  /** Creation and edition modal function and state definition */
  const { showModal, setShowModal } = useContext(DisplayVideoActionContext);

  /** Creation and edition modal element definition */
  const { element } = useContext(DisplayVideoActionContext);

  const actionTemplate = (
    <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">      
      {element && element.video_code ? 
        <Modal.Body>
          <div className="text-center" dangerouslySetInnerHTML={{__html: element.video_code}}>            
          </div>
        </Modal.Body> : null
      }      
    </Modal>
  );

  /** END EDIT FORM ACTION */

  return {
    displayVideoFunction,
    actionTemplate,
  };
}

export default useDisplayVideoAction;
