import { useContext } from "react";
import { DownloadPDFContext } from "./DownloadPDFActionContext";
import useDownload from "./useDownload";

function useDownloadPDFAction(): any {

  const {element, setElement} = useContext(DownloadPDFContext);

  const onSuccess = (data: any) => {    
    const blob = new Blob([data], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = element.document[0].filename;
    link.click();    
  };

  const download = useDownload();
  const downloadPDFFunction = (element: any, url: string) => {
    setElement(element);
    download(url, onSuccess)
  };
  
  return {
    downloadPDFFunction,    
  };
}

export default useDownloadPDFAction;
