import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RowAction } from "@beeldit/core/enums/RowAction.enum";
import { TableAction } from "@beeldit/core/enums/TableAction.enum";
import { FinalTableConfig } from "@beeldit/core/models/FinalTableConfig.interface";

const tableConfig: FinalTableConfig = {
  index: null,
  actions: [
    {
      name: TableAction.CREATE,
      label: "create_balance_movement",
      showLabel: true,
      allowed: true,
      show: true,
      action: null,
      icon: icon({ name: "plus", style: "solid" }),
      roles: ["admin"],
    },
  ],
  rowActions: [
    {
      name: RowAction.DETAILS,
      label: "details",
      icon: icon({ name: "right-to-bracket", style: "solid" }),
      allowed: true,
      permissions: ["balance_movements.show_own"],
      show: (element: any) => {
        return element.type == 0;
      },
      action: (element: any) => {
        console.log("details", element);
      },
    },
    {
      name: RowAction.EDIT,
      label: "edit",
      icon: icon({ name: "pen-to-square", style: "solid" }),
      allowed: true,
      permissions: ["balance_movements.update"],
      show: (element: any) => {
        return element.type == 0;
      },
      action: (element: any) => {
        console.log("edit", element);
      },
    },
    {
      name: RowAction.DELETE,
      label: "delete",
      icon: icon({ name: "trash", style: "solid" }),
      allowed: true,
      show: true,
      permissions: ["balance_movements.destroy"],
      action: (element: any) => {
        console.log("delete", element);
      },
    }
  ],
  rowConfiguration: {
    class: (element: any) => {
      return null;
    },
  },
  columns: [
    {
      name: "id",
      label: "#",
      key: "id",
      type: "number",
      show: true,
      sortable: true,
    },
    {
      name: "date",
      label: "date",
      key: "date",
      type: "date",
      show: true,
      sortable: true,
    },
    {
      name: "concept",
      label: "concept",
      key: "concept",
      type: "string",
      show: true,
      sortable: true,
    },
    {
      name: "amount",
      label: "amount",
      key: "amount",
      type: "currency",
      currency: "EUR",
      show: true,
      sortable: false,
    },
  ],
  pagination: {
    enabled: true,
    back: true,
  },
};
const getConfig: any = (functions: any) => {
  const {
    getFunction,
    indexFunction,
    initializeFunction,
    prepareRemoveFunction,
    detailsFunction,
  } = functions;
  tableConfig.actions[0].action = () => initializeFunction();
  tableConfig.index = () => indexFunction();
  tableConfig.rowActions[0].action = (id: any) => detailsFunction(id);
  tableConfig.rowActions[1].action = (id: any) => getFunction(id);
  tableConfig.rowActions[2].action = (id: any) => prepareRemoveFunction(id);  
  return tableConfig;
};
export default getConfig;
