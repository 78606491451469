import LevelSelector from "@main/levels/components/LevelSelector";

const filterUIConfig = {
  uiScheme: {
    classNames: "filters-container",
    level_id: {
      "ui:widget": "level-selector",
      "ui:options": {
        label: false, // Oculta el título del campo
      },
    },
    search: {
      "ui:options": {
        label: false, // Oculta el título del campo
        placeholder: "Buscar...",
      },
    },
  },
  widgets: {
    "level-selector": LevelSelector
  },
};
const getFilterUIConfig: any = (type: string) => {
  return filterUIConfig;
};
export default getFilterUIConfig;
