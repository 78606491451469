import { useEffect } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import useKPIsAction from "../actions/kpis/useKPIsAction";

function PharmacyKPIsPage(props: any) {
  const { filters } = props;

  const { kpisFunction, actionTemplate } =
    useKPIsAction();

  useEffect(() => {
    kpisFunction(filters.pharmacy_id);
  }, []);
  return (actionTemplate)
}

export default PharmacyKPIsPage;
