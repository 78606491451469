export class Pharmacy {
    public id: number;
    public name: string;
    public balance: number;
    public fiscal_name: string;
    public cif: string;
    public contact_email: string;
    public contact_phone: string;
    public contact_mobile_phone: string;
    public address: string;
    public postal_code: string;
    public rrss: any;
    public city: string;
    public state_id: number;
    public level_id: number;
    public purchases_responsible_name: string;
    public latitude: number;
    public longitude: number;
    public last_incentive: number;
    public commercial_id: number;
    public created_at: string;
    public updated_at: string;
    public deleted_at: string;


    constructor() {
        this.id = 0;
        this.name = '';
        this.balance = 0;
        this.fiscal_name = '';
        this.cif = '';
        this.contact_email = '';
        this.contact_phone = '';
        this.contact_mobile_phone = '';
        this.address = '';
        this.postal_code = '';
        this.rrss = {};
        this.city = '';
        this.state_id = 0;
        this.level_id = 0;
        this.purchases_responsible_name = '';
        this.latitude = 0;
        this.longitude = 0;
        this.last_incentive = 0;
        this.commercial_id = 0;
        this.created_at = '';
        this.updated_at = '';
        this.deleted_at = '';
        
    }
}