import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { ListActionContext } from "./ListActionContext";
import index from "@beeldit/core/services";

function useIndex() {
  const { tableFilters, paginationConfig, orderBy, setTableData } =
    useContext(ListActionContext);
  const errorHandler = useErrorHandler();
  return () => {
    let filterPath = "relations=event,eventRule";
    if (tableFilters) {
      for (const [key, value] of Object.entries(tableFilters)) {
        if (value) {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (paginationConfig) {
      for (const [key, value] of Object.entries(paginationConfig)) {
        if (value && key !== "total_items") {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (orderBy) {
      filterPath += `&orderBy=${orderBy}`;
    }
    index("pharmacies", filterPath)
      .then((response: any) => {
        setTableData(response.data);
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useIndex;
