import { useContext, useEffect } from "react";
import useIndex from "./useIndex";
import { User } from "@beeldit/user-and-access/users/models/User";
import AddQueryParamsToURL from "@beeldit/core/helpers/AddQueryParamsToURL";
import { GridListActionContext } from "./GridListActionContext";
import useFilters from "@beeldit/core/helpers/useFilters";
import getFilterUIConfig from "@main/resources/configs/filterUIConfig";
import getFiltersConfig from "@main/resources/configs/filterConfig";
import { t } from "i18next";
import BeelditTableLoadMorePagination from "@beeldit/core/components/BeelditTableLoadMorePagination";
import moment from "moment";
import BeelditTableFilters from "@beeldit/core/components/BeelditTableFilters";

function useGridListAction(
  user: any,
  filters: any = {},
  functions: any = {}
): any {
  const addQueryParamsToURL: any = AddQueryParamsToURL();

  /** Crud functions */
  const index = useIndex();
  const indexFunction = () => index();

  /** Filter loading */
  const { filtersLoaded, setFiltersLoaded } = useContext(GridListActionContext);
  const { tableFilters, setTableFilters } = useContext(GridListActionContext);
  const { tableConfig, setTableConfig } = useContext(GridListActionContext);
  useFilters({ ...{}, ...filters }, GridListActionContext);

  /**Order by config */
  const { orderBy, setOrderBy } = useContext(GridListActionContext);

  const onOrderByChange = (orderBy: string) => {
    setOrderBy(orderBy);
  };

  /**
   * Pagination config
   */
  const { paginationConfig, setPaginationConfig } = useContext(
    GridListActionContext
  );

  const onPaginationChange = (page: number) => {
    setPaginationConfig({ ...paginationConfig, page: page });
  };

  /** Index table funcion definition */
  const { tableData } = useContext(GridListActionContext);

  useEffect(() => {
    if (filtersLoaded) {
      index();
    }
  }, [tableFilters, paginationConfig, filtersLoaded, orderBy]);

  /**<newActionsFunctions>**/

  /** Table Filters configuration */
  const filterUIConfig = getFilterUIConfig(user);
  const filterConfig = {
    schema: getFiltersConfig(user),
    uiSchema: filterUIConfig.uiScheme,
    widgets: filterUIConfig.widgets,
    element: tableFilters,
    onChange: (form: any) => {
      setTableFilters(form.formData);
      addQueryParamsToURL(form.formData);
    },
  };

  const { downloadFunction } = functions;

  const formatDateTime = (date: any) => {
    if (date) {
      date = moment(date);
      return date.format("DD/MM/YYYY HH:mm");
    }
  };

  let actionTemplate =
    filtersLoaded && tableConfig ? (
      <>
        <BeelditTableFilters formConfig={filterConfig} />
        <div className="row row-cols- row-cols-sm-1 row-cols-md-2 row-cols-lg-3 g-4 mt-5">
          {tableData.elements.map((offer: any) => {
            return (
              <div className="">
                <div className="recurso-download">
                  <div className="recurso-body">
                    <img
                      src={offer.miniature[0].url}
                      alt={offer.miniature[0].filename}
                      className="img-fluid"
                    ></img>
                    <div>
                      <h5>{offer.name}</h5>
                      <p>{offer.description}</p>
                    </div>
                  </div>
                  <div className="recurso-footer">
                    <div></div>
                    <a
                      className="btn btn-sm"
                      onClick={() => {
                        downloadFunction(offer.document[0].url);
                      }}
                    >
                      {t("download")}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <BeelditTableLoadMorePagination
          paginationConfig={paginationConfig}
          totalElements={tableData.totalElements}
          onPaginationChange={onPaginationChange}
        ></BeelditTableLoadMorePagination>
      </>
    ) : (
      <>
        Filters: {filtersLoaded.toString()} table config: {!tableConfig}
      </>
    );
  /** END EDIT TABLE ACTION */

  return {
    indexFunction,
    actionTemplate,
    setTableConfig,
  };
}

export default useGridListAction;
