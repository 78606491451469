import { useContext, useEffect, useState } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import { t } from "i18next";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useDetailsAction from "../actions/details/useDetailsAction";
import BalanceMovementsPage from "@main/balance_movements/pages/BalanceMovementsPage";
import { ListActionProvider as BalanceMovementListActionProvider } from "./../../balance_movements/actions/list/ListActionContext";
import { CreateActionProvider as BalanceMovementCreateActionProvider } from "./../../balance_movements/actions/create/CreateActionContext";
import { RemoveActionProvider as BalanceMovementRemoveActionProvider } from "./../../balance_movements/actions/remove/RemoveActionContext";
import { EditActionProvider as BalanceMovementEditActionProvider } from "./../../balance_movements/actions/edit/EditActionContext";
import { PharmacyContext } from "../contexts/PharmacyContext";
import { DetailsActionContext } from "../actions/details/DetailsActionContext";
import { KPIsActionProvider } from "../actions/kpis/KPIsActionContext";
import PharmacyKPIsPage from "./PharmacyKPIsPage";

function PharmacyTabsPage() {
  const { user } = useContext(AuthContext);

  const {element} = useContext(DetailsActionContext)

  let { pharmacyId } = useParams();

  if (!pharmacyId) {
    pharmacyId = "0";
  }

  const {
    getFunction,
    onSubmitFunction,
    actionTemplate: detailsActionTemplate,
  } = useDetailsAction(parseInt(pharmacyId));

  useEffect(() => {
    getFunction(pharmacyId);
  }, [pharmacyId]);

  /** Filtro con el que inicializamos las tablas asociadas al comercio en el que nos encontramos */
  const filters = { pharmacy_id: parseInt(pharmacyId) };
  const formDefaultValues = { pharmacy_id: parseInt(pharmacyId) };
  const formFilterValues = { pharmacy_id: parseInt(pharmacyId) };

  return (
    <>
      {
        element ? <h4 className="float-end">{element.name}</h4> : null
      }
      <Tabs
        defaultActiveKey="pharmacy"
        id="uncontrolled-tab-example"
        className=""
      >
        <Tab eventKey="pharmacy" title={t('pharmacy_data')}>
          <Card>              
              <Card.Body>
                {detailsActionTemplate}
                <div className="text-end">
                  <Button variant="outline-primary" onClick={onSubmitFunction}>
                    {t("save_changes")}
                  </Button>
                </div>
              </Card.Body>
            </Card>
        </Tab>
        <Tab eventKey="profile" title="KPIs">
          <KPIsActionProvider>
            <PharmacyKPIsPage filters={filters}></PharmacyKPIsPage>
          </KPIsActionProvider>
        </Tab>
        <Tab eventKey="contact" title={t('balance_movements')}>
          <BalanceMovementListActionProvider>
              <BalanceMovementRemoveActionProvider>
                <BalanceMovementCreateActionProvider>
                  <BalanceMovementEditActionProvider>
                    <BalanceMovementsPage
                      filters={filters}
                      formDefaultValues={formDefaultValues}
                      formFilterValues={formFilterValues}
                    />
                  </BalanceMovementEditActionProvider>
                </BalanceMovementCreateActionProvider>
              </BalanceMovementRemoveActionProvider>
            </BalanceMovementListActionProvider>
        </Tab>
      </Tabs>
    </>
  );
}

export default PharmacyTabsPage;
