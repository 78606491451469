import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";
import checkPermissions from "@beeldit/user-and-access/auth/services/checkPermissions";
import checkRoles from "@beeldit/user-and-access/auth/services/checkRoles";
import { t } from "i18next";

function BeelditTableHeaderActions(props: any) {
  const { actions } = props;
  const { user } = useContext(AuthContext);

  return (
    <>
      {actions.map((action: any) => {
        if (
          checkPermissions(user, action.permissions) &&
          checkRoles(user, action.roles) &&
          action.show
        ) {
          return (
            <Button
              className="icon-button"
              key={action.name}
              onClick={() => action.action()}
            >
              <FontAwesomeIcon icon={action.icon} className="me-2" />
              {
                action.showLabel ? t(action.label) : null
              }              
            </Button>
          );
        }
        return null;
      })}
    </>
  );
}

export default BeelditTableHeaderActions;
