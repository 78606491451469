import React, { createContext, useState } from "react";

interface ExportActionContextType {    
  exportFilters: any;
  setExportFilters: (tableFilters: any) => void;    
  orderBy: string | null;
  setOrderBy: (orderBy: string | null) => void;    
}

export const ExportActionContext = createContext<ExportActionContextType>({
  exportFilters: {},
  setExportFilters: () => {},
  orderBy: null,
  setOrderBy: () => {},
});

interface ExportActionProviderProps {
  children: React.ReactNode;
}

export const ExportActionProvider: React.FC<ExportActionProviderProps> = ({
  children,
}) => {
  const [exportFilters, setExportFilters] = useState({});
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const value = {
    exportFilters,
    setExportFilters,
    orderBy,
    setOrderBy,
  };
  return (
    <ExportActionContext.Provider value={value}>
      {children}
    </ExportActionContext.Provider>
  );
};
