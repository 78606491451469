import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function BeelditScrollToTop() {
  const navigate = useNavigate();
  const location = useLocation();
  let lastSearch = '';

  useEffect(() => {
    // No queremos que se haga scroll si el search es distinto
    if (location.search !== lastSearch) {
      lastSearch = location.search;
      return;
    }
    window.scrollTo(0, 0);
  }, [location, navigate]);

  return null;
}

export default BeelditScrollToTop;
