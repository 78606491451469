import { useContext, useRef } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import { GridListActionContext } from "./GridListActionContext";
import index from "@beeldit/core/services";
import axios, { CancelTokenSource } from "axios";

function useIndex() {
  const { tableFilters, lastTableFilters, paginationConfig, orderBy, tableData, setTableData, setLastTableFilters, setPaginationConfig } =
    useContext(GridListActionContext);
  const errorHandler = useErrorHandler();
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);
  return () => { 

    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("Petición cancelada debido a una nueva petición");
    }

    // Crea un nuevo token de cancelación
    cancelTokenRef.current = axios.CancelToken.source();

    if(JSON.stringify(tableFilters) !== JSON.stringify(lastTableFilters)){
      setPaginationConfig({ page: 1, elementsPerPage: 8 });
      setLastTableFilters(tableFilters);
      setTableData({ elements: [] });
    }
    let filterPath = "active=true";
    if (tableFilters) {
      for (const [key, value] of Object.entries(tableFilters)) {
        if (value) {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (paginationConfig) {
      for (const [key, value] of Object.entries(paginationConfig)) {
        if (value && key !== "total_items") {
          filterPath += `&${key}=${value}`;
        }
      }
    }
    if (orderBy) {
      filterPath += `&orderBy=${orderBy}`;
    }
    index("contents", filterPath, { cancelToken: cancelTokenRef.current.token })
      .then((response: any) => {
        setTimeout(() => {
          response.data.elements = tableData.elements.concat(
            response.data.elements
          );
          setTableData(response.data);
        }, 1000);        
      })
      .catch((error: any) => {
        if (axios.isCancel(error)) {
          console.log('Petición cancelada:', error.message);
        } else {
          errorHandler(error);
        }
      });
  };
}

export default useIndex;
