import React, { createContext, useState, useContext } from "react";
import { AuthContext } from "@beeldit/user-and-access/auth/AuthContext";

interface ProfileActionContextType {  
  backendFormErrors: any;
  setBackendFormErrors: (backendFormErrors: any) => void;    
  element: any;
  setElement: (element: any) => void;  
  editionMode: boolean;
  setEditionMode: (editionMode: boolean) => void;
}

export const ProfileActionContext = createContext<ProfileActionContextType>({  
  backendFormErrors: {},
  setBackendFormErrors: () => {},  
  element: null,
  setElement: () => {},  
  editionMode: false,
  setEditionMode: () => {},
});

interface ProfileActionProviderProps {
  children: React.ReactNode;
}

export const ProfileActionProvider: React.FC<ProfileActionProviderProps> = ({
  children,
}) => {  
  const [backendFormErrors, setBackendFormErrors] = useState(null);
  const [element, setElement] = useState<any>(null);  
  const [editionMode, setEditionMode] = useState(false);
  const backgroundSrc = process.env.REACT_APP_BACKGROUND_AUTH;
  const { user } = useContext(AuthContext);

  const value = {    
    backendFormErrors,
    setBackendFormErrors,
    element,
    setElement,    
    editionMode,
    setEditionMode,
  };
  return (
      <>
        <section className="hero not-full" style={{backgroundImage: "url(/" + backgroundSrc + ")"}}>

        </section>
        <div className="container">
          <div className="profile">
            <img src={user?.extra_data.pharmacy.logo[0]?.url ?? "/pharma_logo_cruz.png"} alt=""/>
          </div>
        </div>
        <div className="container">
          <div className="row align-items-center mb-5">
            <ProfileActionContext.Provider value={value}>
              {children}
            </ProfileActionContext.Provider>
          </div>
        </div>

      </>

  );
};
