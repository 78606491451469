import React, { createContext, useState } from "react";

interface DisplayVideoActionContextType {    
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  element: any;
  setElement: (element: any) => void;  
}

export const DisplayVideoActionContext = createContext<DisplayVideoActionContextType>({  
  showModal: false,
  setShowModal: () => {},
  element: null,
  setElement: () => {},  
});

interface DisplayVideoActionProviderProps {
  children: React.ReactNode;
}

export const DisplayVideoActionProvider: React.FC<DisplayVideoActionProviderProps> = ({
  children,
}) => {  
  const [showModal, setShowModal] = useState(false);
  const [element, setElement] = useState<any>(null);  

  const value = {    
    showModal,
    setShowModal,
    element,
    setElement,    
  };
  return (
    <DisplayVideoActionContext.Provider value={value}>      
      {children}
    </DisplayVideoActionContext.Provider>
  );
};
