import { getApi } from "@beeldit/core/helpers/api";

function sendForm(loginData: any, onSuccess: any, onError: any, setLoading: any) {
  const sendForm = async (formData: any) => {
    try {
      setLoading(true);
      const response = await getApi().post("/contact-form", formData);
      onSuccess(response.data);
      setLoading(false);
      console.log(response);
    } catch (error: any) {
      onError(error.response.data);
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  sendForm(loginData);
}

export default sendForm;
