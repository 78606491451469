import { useContext } from "react";
import useErrorHandler from "@beeldit/core/errors/useErrorHandler";
import remove from "@beeldit/core/services/remove";
import { RemoveActionContext } from "./RemoveActionContext";

function useRemove() {
  const { elementId, setShowModal } =
    useContext(RemoveActionContext);
  const errorHandler = useErrorHandler();
  return (onSuccess: any) => {
    remove("sales", elementId)
      .then((response: any) => {
        setShowModal(false);
        onSuccess();
      })
      .catch((error: any) => {
        errorHandler(error);
      });
  };
}

export default useRemove;
